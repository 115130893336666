<template>

  <div class="layout-subcontainer cmp-subcont">
    <div class="box-wrap">
      <div class="pa-0">
        <h1 class="tit-h1 d-flex">
          캠페인 관리
          <div class="ml-auto">
            <v-btn 
              outlined 
              class="btn-etc2 ml-2"
              @click="fnSearchCamp" id="btnSearch">
              조회
            </v-btn>
          </div>
        </h1>

        <div class="box-ct mt-2">
          <div class="table-form d-flex">
            <table>
              <colgroup>
                <col width="7%" />
                <col width="25%" />
                <col width="7%" />
                <col width="25%" />
                <col width="7%" />
                <col width="25%" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">건설사명</v-badge>
                  </th>
                  <td>
                    <div class="form-inp full">
                      <v-autocomplete 
                        :items="constructionCompanyNameArr" 
                        item-text="CD_NM" 
                        item-value="CD" 
                        outlined hide-details
                        placeholder="선택하세요" 
                        v-model="CONSTRUCTION_COMPANY_NAME_SEARCH" 
                        @change=chkCtcpCd>
                        <!-- <template v-slot:label>
                          건설사명
                        </template> -->
                      </v-autocomplete>
                    </div>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">사업지명</v-badge>
                  </th>
                  <td>
                    <div class="form-inp full">
                      <v-autocomplete 
                        :items="businessPlaceArr" 
                        item-text="CD_NM" 
                        item-value="CD" 
                        outlined hide-details
                        placeholder="선택하세요" 
                        v-model="BUSINESS_PLACE_SEARCH"
                        :disabled="businessPlaceArr.length == 0 ? true : false">
                      </v-autocomplete>
                    </div>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">캠페인명</v-badge>
                  </th>
                  <td colspan="3">
                    <v-text-field 
                      class="form-inp full" 
                      outlined hide-details 
                      v-model.trim="CAMP_NAME_SEARCH"
                      @keyup.enter="searchCampInfo"
                      >
                    </v-text-field>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- 목록 -->
        <div class="box-wrap">
          <h2 class="tit-h2">
            캠페인 목록
          </h2>
          <div class="box-ct">
            <v-data-table 
              v-model="gridSelected" 
              :search="gridSearch" 
              dense 
              max-height="300px" 
              fixed-header 
              hide-default-footer
              :headers="gridDataHeaders" 
              :items="gridDataText" 
              :items-per-page="itemsPerPage" 
              :page.sync="page" 
              item-key="NUM"
              class="grid-default" 
              @page-count="pageCount = $event" 
              :item-class="isActiveRow" 
              calculate-widths
              no-data-text="검색된 결과가 없습니다."
              show-select
              id="gridDatas"
            >

              <template v-slot:item.QUTN_ID={item}>
                <div>
                  <v-btn v-if="item.QUTN_ID!=null&&item.QUTN_ID!=''" :class="{color:item.QUTN_ID, small:item.QUTN_ID===false}" @click="fnSurveyBtn(item.QUTN_ID)">설문</v-btn>
                  <v-btn v-if="item.QUTN_ID==null||item.QUTN_ID==''" :class="{color:item.QUTN_ID, small:item.QUTN_ID===false}">설문</v-btn>
                </div>
              </template>
              
              <template v-slot:item.CMP_QUTN_ID={item}>
                <div>
                  <v-btn v-if="item.CMP_QUTN_ID!=null&&item.CMP_QUTN_ID!=''" :class="{color:item.CMP_QUTN_ID, small:item.CMP_QUTN_ID===false}" @click="fnSurveyBtn(item.CMP_QUTN_ID)">현장방문 설문</v-btn>
                  <v-btn v-if="item.CMP_QUTN_ID==null||item.CMP_QUTN_ID==''" :class="{color:item.CMP_QUTN_ID, small:item.CMP_QUTN_ID===false}">현장방문 설문</v-btn>
                </div>
              </template>

              <template v-slot:item.BUSS_NAME={item}>
                <div @click="fnRowClick(item)">
                  {{ item.BUSS_NAME }}
                </div>
              </template>
              <template v-slot:item.CMP_NAME={item}>
                <div @click="fnRowClick(item)">
                  {{ item.CMP_NAME }}
                </div>
              </template>
              <template v-slot:item.CMP_TYPE_NAME={item}>
                <div @click="fnRowClick(item)">
                  {{ item.CMP_TYPE_NAME }}
                </div>
              </template>
              <template v-slot:item.COMP_URL={item}>
                <div @click="fnRowClick(item)">
                  {{ item.COMP_URL }}
                </div>
              </template>
              <template v-slot:item.CMP_STDT={item}>
                <div @click="fnRowClick(item)">
                  {{ item.CMP_STDT }}
                </div>
              </template>
              <template v-slot:item.CMP_ENDT={item}>
                <div @click="fnRowClick(item)">
                    {{ item.CMP_ENDT }}
                </div>
              </template>

              <template v-slot:item.MAX_RESERVATION={item}>
                <div @click="fnRowClick(item)">
                    {{ String(item.MAX_RESERVATION.replace(/[^0-9]/g, '')).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                </div>
              </template>

              <template v-slot:item.UPLD_GROUP_ID={item}>
                <div @click="fnRowClick(item)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn 
                        dark 
                        small 
                        class="btn-main-search pa-0 ml-2" 
                        min-width="32px" height="32px" 
                        plain
                        :disabled="item.FILE_KEY_MAIN_IMAGE==''
                          &&item.FILE_KEY_CONSULTATION_APPLICATION_IMAGE==''
                          &&item.FILE_KEY_SERVICE_LOGO=='' ? true : false"
                        v-bind="attrs"
                        v-on="on">
                        <v-icon small class="svg-main-search2"></v-icon>
                      </v-btn>
                    </template>
                    <span>
                      <img
                        v-if="item.FILE_KEY_MAIN_IMAGE!=''"
                        class="white--text align-end"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        :src="baseUrl+'api/file/hlw/images/'+item.FILE_KEY_MAIN_IMAGE"
                        :alt="item.MAIN_IMAGE_NAME"
                        @error="replaceImg"
                        :width=170 :height=170 />
                      <img
                        v-if="item.FILE_KEY_CONSULTATION_APPLICATION_IMAGE!=''"
                        class="white--text align-end"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        :src="baseUrl+'api/file/hlw/images/'+item.FILE_KEY_CONSULTATION_APPLICATION_IMAGE"
                        :alt="item.CONSULTATION_APPLICATION_IMAGE_NAME"
                        @error="replaceImg"
                        :width=170 :height=170 />
                      <img
                        v-if="item.FILE_KEY_SERVICE_LOGO!=''"
                        class="white--text align-end"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        :src="baseUrl+'api/file/hlw/images/'+item.FILE_KEY_SERVICE_LOGO"
                        :alt="item.SERVICE_LOGO_NAME"
                        @error="replaceImg"
                        :width=170 :height=170 />
                    </span>
                  </v-tooltip>
                </div>
              </template>

            </v-data-table>
            <div class="grid-paging text-center pt-2">
              <v-pagination 
                v-model="page" 
                :length="pageCount" 
                :total-visible="totalVisible" 
                next-icon="svg-paging-next"
                prev-icon="svg-paging-prev">
              </v-pagination>
              <p class="caption text-left">
                총 <strong class="clr-error">{{ totalCount }}</strong>건
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box-wrap">
      <!-- 캠페인 상세내용 -->
      <div class="ml-6 flex-grow-1">
        <h2 class="tit-h2 d-flex">
          캠페인 상세내용
          <div class="ml-auto">
            <v-btn outlined class="btn-default" 
              @click="newAdd"
              id="btnNew">
              신규
            </v-btn>
            <v-btn outlined class="btn-etc ml-2"
              @click="deleteInfo" 
              id="btnDel">
              삭제
            </v-btn>
            <v-btn outlined class="btn-point ml-2"
              @click="saveCampDetail">
              {{ CMP_BOOL ? '수정' : '저장' }}
            </v-btn>
          </div>
        </h2>
        <div class="box-ct mt-2">
          <v-form ref="form" v-model="VALID" lazy-validation>
            <div class="table-form save-form d-flex">
              <table>
                <colgroup>
                  <col width="10%">
                  <col width="40%">
                  <col width="10%">
                  <col width="40%">
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">
                        건설사명
                      </v-badge>
                    </th>
                    <td>
                      <v-autocomplete 
                        :items="constructionCompanyNameFormArr"
                        :rules="validateRules.constructionCompanyName" 
                        required
                        item-text="CD_NM" item-value="CD"
                        outlined hide-details 
                        class="form-inp table-form-tag" 
                        placeholder="선택하세요"
                        :disabled="formAllDisable"
                        v-model="CONSTRUCTION_COMPANY_NAME" 
                        @change=chkFormCtcpCd>
                      </v-autocomplete>
                    </td>

                    <th scope="row">
                      <v-badge dot color="#EF9191" text:align="center">
                        사업지명
                      </v-badge>
                    </th>
                    <td>
                      <v-autocomplete 
                        :items="businessPlaceFormArr" 
                        :rules="validateRules.businessPlaceFormName" 
                        required
                        item-text="CD_NM" item-value="CD" 
                        outlined hide-details 
                        class="form-inp"
                        placeholder="선택하세요" 
                        @change="bussDataChk"
                        name="businessPlaceFormName"
                        :disabled="businessPlaceFormArr.length == 0 || formAllDisable ? true : false"
                        v-model="BUSINESS_PLACE">
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <!-- 공통코드로 관리 -->
                      <v-badge dot color="#EF9191">캠페인 타입</v-badge>
                    </th>
                    <td>
                      <v-autocomplete 
                        :items="campaignTypeFormArr" 
                        :rules="validateRules.campaignType"
                        required
                        item-text="CD_NM" 
                        item-value="CD" 
                        outlined 
                        hide-details 
                        class="form-inp table-form-tag"
                        placeholder="선택하세요" 
                        @change="comTypeChange" 
                        :disabled="formAllDisable"
                        v-model="CAMPAIGN_TYPE"
                      >
                      </v-autocomplete>
                    </td>
                    <th scope="row">
                      <!-- <v-badge dot color="#EF9191"> -->
                        챗봇 타입
                      <!-- </v-badge> -->
                    </th>
                    <td>
                      <v-autocomplete :items="chatbotTypeFormArr" item-text="CD_NM" item-value="CD" outlined hide-details
                        class="form-inp table-form-tag" placeholder="선택하세요" id="chatbotType" 
                        :disabled=camTypeBool2
                        v-model="CHATBOT_TYPE">
                      </v-autocomplete>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">캠페인명</v-badge>
                    </th>
                    <td>
                      <v-text-field class="form-inp table-form-tag" 
                        outlined hide-details
                        :rules="validateRules.campName" 
                        required
                        :disabled="formAllDisable"
                        v-model="CAMP_NAME">
                      </v-text-field>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">상태</v-badge>
                    </th>
                    <td>
                      <v-autocomplete :items="statusFormArr" item-text="CD_NM" item-value="CD" outlined hide-details
                        class="form-inp table-form-tag" placeholder="선택하세요" 
                        :rules="validateRules.status"
                        required
                        :disabled="formAllDisable"
                        v-model="USE_YN">
                      </v-autocomplete>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <!-- <v-badge dot color="#EF9191"> -->
                      메인 이미지
                      <!-- </v-badge> -->
                    </th>
                    <td>
                      <v-text-field 
                        class="form-inp table-form-tag form-image" 
                        name="mainImage" 
                        outlined hide-details
                        accept="image/*"
                        type="file"  
                        :disabled="formAllDisable"
                        v-model="MAIN_IMAGE">
                      </v-text-field>
                      <!-- 새로고침 버튼 -->
                      <v-btn dark small plain class="btn-main-refresh pa-0 ml-2 table-form-tag" min-width="32px"
                        height="32px" @click="fnClearParams">
                        <v-icon small class="svg-main-refresh"></v-icon>
                      </v-btn>
                    </td>
                    <th scope="row">
                      <!-- <v-badge dot color="#EF9191"> -->
                      상담 신청 이미지
                      <!-- </v-badge> -->
                    </th>
                    <td>
                      <v-text-field 
                        class="form-inp table-form-tag form-image" 
                        name="consultationApplicationImage"
                        outlined hide-details 
                        accept="image/*"
                        type="file" 
                        :disabled="formAllDisable"
                        v-model="CONSULTATION_APPLICATION_IMAGE">
                      </v-text-field>
                      <!-- 새로고침 버튼 -->
                      <v-btn dark small plain class="btn-main-refresh pa-0 ml-2 table-form-tag" min-width="32px"
                        height="32px" @click="fnClearParams">
                        <v-icon small class="svg-main-refresh"></v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <!-- <v-badge dot color="#EF9191"> -->
                      서비스 로고
                      <!-- </v-badge> -->
                    </th>
                    <td>
                      <v-text-field 
                        class="form-inp table-form-tag form-image" 
                        name="serviceLogo" 
                        outlined hide-details
                        accept="image/*"
                        type="file" 
                        :disabled="formAllDisable"
                        v-model="SERVICE_LOGO">
                      </v-text-field>
                      <!-- 새로고침 버튼 -->
                      <v-btn dark small plain class="btn-main-refresh pa-0 ml-2 table-form-tag" min-width="32px"
                        height="32px" @click="fnClearParams">
                        <v-icon small class="svg-main-refresh"></v-icon>
                      </v-btn>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">버튼 텍스트</v-badge>
                    </th>
                    <td>
                      <v-text-field 
                        class="form-inp table-form-tag" 
                        outlined hide-details
                        :rules="validateRules.buttonText"
                        required 
                        :disabled="formAllDisable"
                        v-model.trim="BUTTON_TEXT">
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <!-- <v-badge dot color="#EF9191"> -->
                      중복참여여부
                      <!-- </v-badge> -->
                    </th>
                    <td>
                      <v-checkbox 
                        :on-icon="'svg-chk-on'" 
                        :off-icon="'svg-chk-off'" 
                        class="form-inp table-form-tag"
                        id="duplicateParticipationStatus" 
                        :disabled=camTypeBool2 
                        v-model="DUPLICATE_PARTICIPATION_STATUS">
                      </v-checkbox>
                    </td>
                    <th scope="row">
                      <!-- <v-badge dot color="#EF9191"> -->
                      완료 링크
                      <!-- </v-badge> -->
                    </th>
                    <td>
                      <v-text-field class="form-inp table-form-tag" 
                        outlined hide-details 
                        :disabled=formAllDisable
                        v-model.trim="FINISHED_LINK">
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">기간</v-badge>
                    </th>
                    <td>
                      <v-menu 
                        v-model="menu1" 
                        :close-on-content-click="false" 
                        :nudge-right="40"
                        transition="scale-transition" 
                        offset-y 
                        min-width="auto" 
                        content-class="calendar-modal"
                        color="#FFF"
                        >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field 
                            :rules="validateRules.datesRange" 
                            required
                            v-model="DATES_RANGE"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            outlined
                            hide-details
                            class="form-inp icon-calendar" 
                            append-icon="svg-calendar" 
                            :disabled="formAllDisable"
                            name="datesRText">
                          </v-text-field>
                        </template>
                        <div class="datepicker-range">
                          <v-date-picker 
                            v-model="DATES[0]" 
                            no-title 
                            color="#F4B228" 
                            locale="ko-KR"
                            :first-day-of-week="0" 
                            :day-format="mixin_getDate" 
                            @change="startDate">
                          </v-date-picker>
                          <v-date-picker 
                            v-model="DATES[1]" 
                            color="#F4B228" 
                            no-title 
                            :min="DATES[0]" 
                            locale="ko-KR"
                            :first-day-of-week="0" 
                            :day-format="mixin_getDate" 
                            @change="endDate">
                          </v-date-picker>
                        </div>
                        <div class="text-center pt-3 pb-3">
                          <v-btn outlined class="btn-default" @click="menu1 = false">
                            확인
                          </v-btn>
                        </div>
                      </v-menu>
                    </td>
                    <th scope="row">
                      <!-- <v-badge dot color="#EF9191"> -->
                      최대예약
                      <!-- </v-badge> -->
                    </th>
                    <td>
                      <v-text-field 
                        class="form-inp"
                        outlined 
                        hide-details 
                        oninput="this.value = String(this.value.replace(/[^0-9]/g, '')).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                        :disabled="formAllDisable"
                        v-model="MAX_RESERVATION">
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">
                        챗봇 오픈일자
                      </v-badge>
                    </th>
                    <td>
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        content-class="calendar-modal dateTime"
                        color="#FFF"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateFormatted2"
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            hide-details
                            class="form-inp icon-calendar"
                            append-icon="svg-calendar"
                            readonly
                            :disabled="formAllDisable"
                          ></v-text-field>
                        </template>
                        <div class="datepicker-range">
                          <v-date-picker
                            v-model="CMP_OPEN_DT"
                            color="#F4B228"
                            show-current="false"
                            :first-day-of-week="0"
                            locale="ko-KR"
                            :day-format="mixin_getDate"
                          ></v-date-picker>
                          <v-time-picker
                            v-model="CMP_OPEN_TIME"
                            :allowed-minutes="allowedStep"
                            color="#F4B228"
                            format="24hr"
                            @click:minute="$refs.menu2.save()"
                          ></v-time-picker>
                        </div>
                      </v-menu>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">
                        챗봇 마감일자
                      </v-badge>
                    </th>
                    <td>
                      <v-menu
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        content-class="calendar-modal dateTime"
                        color="#FFF"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateFormatted3"
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            hide-details
                            readonly
                            class="form-inp icon-calendar"
                            append-icon="svg-calendar"
                            :disabled="formAllDisable"
                          ></v-text-field>
                        </template>
                        <div class="datepicker-range">
                          <v-date-picker
                            v-model="CMP_END_DT"
                            color="#F4B228"
                            :first-day-of-week="0"
                            locale="ko-KR"
                            :day-format="mixin_getDate"
                          ></v-date-picker>
                          <v-time-picker
                            v-model="CMP_END_TIME"
                            :allowed-minutes="allowedStep"
                            color="#F4B228"
                            format="24hr"
                            @click:minute="$refs.menu3.save()"
                          ></v-time-picker>
                        </div>
                      </v-menu>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <!-- <v-badge dot color="#EF9191"> -->
                        현장방문 고객 대기 시작번호
                      <!-- </v-badge> -->
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp"
                        outlined 
                        style="width:5vw;"
                        type="number"
                        :disabled=camTypeBool3
                        v-model.number.lazy="VISIT_STR_NUM"
                      ></v-text-field>
                    </td>
                    <th>
                      <!-- <v-badge dot color="#EF9191"> -->
                        사전방문 예약 잔여인원 ON/OFF
                      <!-- </v-badge> -->
                    </th>
                    <td>
                      <v-checkbox 
                        :on-icon="'svg-chk-on'" 
                        :off-icon="'svg-chk-off'" 
                        class="form-inp table-form-tag" 
                        :disabled=camTypeBool3
                        v-model="REMA_NUM_PEP_ON_OFF">
                      </v-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <!-- <v-badge dot color="#EF9191"> -->
                        설문
                      <!-- </v-badge> -->
                    </th>
                    <td>
                      <v-autocomplete 
                        :items="qutnItems" 
                        item-text="QUTN_NAME" 
                        item-value="QUTN_ID" 
                        outlined hide-details
                        class="form-inp table-form-tag" 
                        placeholder="선택하세요" 
                        :disabled=formAllDisable
                        style="width:100%"
                        v-model="QUTN_ID">
                      </v-autocomplete>
                    </td>
                    <th>
                      <!-- <v-badge dot color="#EF9191"> -->
                        미사전예약자 현장방문 설문
                      <!-- </v-badge> -->
                    </th>
                    <td>
                      <v-autocomplete 
                        :items="qutnItems" 
                        item-text="QUTN_NAME" 
                        item-value="QUTN_ID" 
                        outlined hide-details
                        class="form-inp table-form-tag" 
                        placeholder="선택하세요" 
                        :disabled=formAllDisable
                        style="width:100%"
                        v-model="CMP_QUTN_ID">
                      </v-autocomplete>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-form>
        </div>
      </div>
    </div>
    
    <template>
      <div class="box-wrap">
        <div class="box-ct">
          <v-form ref="subFormOne" v-model="VALID_SUB" lazy-validation>
            <div class="table-form save-form">
              <v-tabs class="tabs-form" slider-color="#6C6E7A" slider-size="3">
                <v-tab
                  ref="subTab1"
                  @click="fnTab(1)"
                  :disabled=camTypeBool>
                  예약시간 설정
                 </v-tab>

                <v-tab
                  @click="fnTab(2)"
                  ref="subTab2">
                  알림톡 설정
                </v-tab>

                <v-tab
                  @click="fnTab(3)"
                  ref="subTab3">
                  참여허용된 전화번호
                </v-tab>
                
                <v-tab-item ref="tab1Item">
                  <v-card-text>
                    <h2 class="tit-h2 d-flex">
                      <div class="ml-auto">
                        <v-btn 
                          outlined 
                          class="btn-point ml-2" 
                          @click="saveCampDetailSubTab1" 
                          :disabled=camTypeBool
                        >
                          {{ TIME_SETT_BTN_BOOL ? '수정' : '저장' }}
                        </v-btn>
                      </div>
                    </h2>
                    <p v-if="!TIME_SETT_BTN_BOOL && !camTypeBool && settTimeTxtBool" style="color:darkred;">※ 캠페인 상세내용 기간과 예약시간 설정에 저장된 데이터가 상이합니다. <br>※ 저장해주세요.</p>
                    <p v-if="RESERV_TIME_COLL_APP" style="color:darkred;">
                      ※ 일괄 적용할 날짜를 선택 후 일별 시간 단위 일괄적용 최대예약(명), 시간 단위, 시간 범위 수정 후 일괄적용 버튼을 눌러주세요.
                      <v-btn 
                        outlined 
                        class="btn-default ml-2" 
                        @click="fnDateBySett" 
                        :disabled=!RESERV_TIME_COLL_APP
                      >
                        일괄적용
                      </v-btn>
                    </p>
                    <v-form ref="subFormOne1" v-model="VALID_SUB_1" lazy-validation>
                      <div class="table-form save-form">
                        <table style="margin-top:7px;">
                          <colgroup>
                            <col width="10%">
                            <col width="30%">
                            <col width="10%">
                            <col width="30%">
                          </colgroup>
                          <tbody>
                            <tr>
                              <th scope="row">
                                일자별 일괄적용
                              </th>
                              <td>
                                <v-checkbox 
                                  :disabled=camTypeBool
                                  :on-icon="'svg-chk-on'" 
                                  :off-icon="'svg-chk-off'" 
                                  class="form-inp table-form-tag"
                                  @click="fnRevTimeCollApp"
                                  v-model="RESERV_TIME_COLL_APP">
                                </v-checkbox>
                              </td>
                              <th>
                                일별 총 예약인원
                              </th>
                              <td>
                                <v-text-field 
                                  class="form-inp table-form-tag" 
                                  outlined hide-details
                                  readonly
                                  @blur=totResPersCal
                                  v-model="TOTAL_RESERV_PERS">
                                </v-text-field>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <v-badge dot color="#EF9191">
                                  기간
                                </v-badge>
                              </th>
                              <td colspan="5">
                                <div class="ml-auto" id="resDayDivId"></div>
                              </td>
                            </tr>
                            <tr v-show="RESERV_TIME_COLL_APP">
                              <th>
                                <v-badge dot color="#EF9191">
                                  선택 일자
                                </v-badge>
                              </th>
                              <td colspan="5">
                                {{ CHO_DATES.length > 0 ? CHO_DATES : ''}}
                                <!-- &nbsp;&nbsp;"{{ CHO_DATES.join(', ') }}"&nbsp;&nbsp; -->
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <v-badge dot color="#EF9191">
                                  일별 시간 단위 일괄적용 최대예약(명)
                                </v-badge>
                              </th>
                              <td>
                                <v-text-field 
                                  class="form-inp table-form-tag" 
                                  outlined hide-details
                                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                                  @keyup=calculFunc
                                  @blur=totResPersCal
                                  :disabled="defaultDis"
                                  v-model="MAX_RESERV_TIME_CNT">
                                </v-text-field>
                              </td>
                              <th>
                                <v-badge dot color="#EF9191">
                                  시간 단위
                                </v-badge>
                              </th>
                              <td>
                                <div class="form-inp free">
                                  <v-text-field 
                                    class="form-inp table-form-tag" 
                                    outlined hide-details
                                    label="시간단위(분)" 
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                                    @keyup="cnslTimeChkFn"
                                    @blur=totResPersCal
                                    :disabled="defaultDis"
                                    v-model="CNSL_TIME_UNIT">
                                  </v-text-field>분
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">
                                <v-badge dot color="#EF9191">
                                  시간 범위
                                </v-badge>
                              </th>
                              <td colspan="5" class="timeRngTd">
                                <div class="form-inp full divWidth">
                                  <v-select 
                                    :rules="validateRules2.timeRangeList" 
                                    v-model="TIME_RANGE_LIST" 
                                    :items="cnslTimeItems" 
                                    item-value="value"
                                    item-text="text" 
                                    multiple
                                    class="timeRngListCls"
                                    @change="chkTimeRangeListFn"
                                    name="timeRngListName"
                                  >
                                    <template v-slot:selection="{ item, index }">
                                      <v-chip small>
                                        <span>{{ item.text }}</span>
                                        <template>
                                          <v-btn
                                            icon
                                            x-small
                                            class="svg-close20 ml-1"
                                            v-show="dropItemRemove === true"
                                            @click.stop="removeDropTime(item)"
                                          ></v-btn>
                                        </template>
                                      </v-chip>
                                    </template>
                                  </v-select>

                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-form>
                  </v-card-text>
                </v-tab-item>

                <v-tab-item ref="tab2Item">
                  <v-card-text>
                    <h2 class="tit-h2 d-flex">
                      <div class="ml-auto">
                        <v-btn outlined class="btn-point ml-2" @click="saveCampDetailSubTab2" id="btnSaveSub2">
                          {{ ALIM_SETT_BTN_BOOL ? '수정' : '저장' }}
                        </v-btn>
                      </div>
                    </h2>
                    <v-form ref="subFormOne2" v-model="VALID_SUB_2" lazy-validation>
                      <div class="table-form save-form">
                        <table style="margin-top:7px;">
                          <colgroup>
                            <col width="10%">
                            <col width="30%">
                            <col width="10%">
                            <col width="30%">
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>
                                사전예약자 현장방문 알림
                              </th>
                              <td>
                                <v-autocomplete 
                                  :items="placeVisitNotiItems" 
                                  item-text="CD_NM" 
                                  item-value="CD" 
                                  outlined 
                                  hide-details 
                                  class="form-inp table-form-tag" 
                                  placeholder="선택하세요" 
                                  v-model="PLACE_VISIT_NOTI">
                                </v-autocomplete>
                              </td>
                              <th>미사전예약자 현장방문 알림</th>
                              <td>
                                <v-autocomplete 
                                  :items="placeNotiItems" 
                                  item-text="CD_NM" 
                                  item-value="CD" 
                                  outlined 
                                  hide-details 
                                  class="form-inp table-form-tag" 
                                  placeholder="선택하세요" 
                                  v-model="PLACE_NOTI">
                                </v-autocomplete>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                방문 예약 알림
                              </th>
                              <td>
                                <v-autocomplete 
                                  :items="visitScheNotiItems" 
                                  item-text="CD_NM" 
                                  item-value="CD" 
                                  outlined 
                                  hide-details 
                                  class="form-inp table-form-tag" 
                                  placeholder="선택하세요" 
                                  v-model="VISIT_SCHE_NOTI">
                                </v-autocomplete>
                              </td>
                              <th>1일전 알림</th>
                              <td>
                                <v-autocomplete 
                                  :items="oneAgoNotiItems" 
                                  item-text="CD_NM" 
                                  item-value="CD" 
                                  outlined 
                                  hide-details 
                                  class="form-inp table-form-tag" 
                                  placeholder="선택하세요" 
                                  v-model="ONE_AGO_NOTI">
                                </v-autocomplete>
                              </td>
                            </tr>
                            <tr>
                              <th>취소 알림</th>
                              <td>
                                <v-autocomplete 
                                  :items="cancelNotiItems" 
                                  item-text="CD_NM" 
                                  item-value="CD" 
                                  outlined 
                                  hide-details 
                                  class="form-inp table-form-tag" 
                                  placeholder="선택하세요" 
                                  v-model="CANCEL_NOTI">
                                </v-autocomplete>
                              </td>
                              <th>상담 신청</th>
                              <td>
                                <v-autocomplete 
                                  :items="appConsultItems" 
                                  item-text="CD_NM" 
                                  item-value="CD" 
                                  outlined 
                                  hide-details 
                                  class="form-inp table-form-tag" 
                                  placeholder="선택하세요" 
                                  v-model="APP_CONSULT">
                                </v-autocomplete>
                              </td>
                            </tr>
                            <tr>
                              <th>내앞 대기자</th>
                              <td>
                                <v-autocomplete 
                                  :items="waitFrontMeItems" 
                                  item-text="CD_NM" 
                                  item-value="CD" 
                                  outlined 
                                  hide-details 
                                  class="form-inp table-form-tag" 
                                  placeholder="선택하세요" 
                                  v-model="WAIT_FRONT_ME">
                                </v-autocomplete>
                              </td>
                              <th>대기 알림</th>
                              <td>
                                <v-autocomplete 
                                  :items="stanNotiItems" 
                                  item-text="CD_NM" 
                                  item-value="CD" 
                                  outlined 
                                  hide-details 
                                  class="form-inp table-form-tag" 
                                  placeholder="선택하세요" 
                                  v-model="STAN_NOTI">
                                </v-autocomplete>
                              </td>
                            </tr>
                            <tr>
                              <th>상담석(호출)</th>
                              <td>
                                <v-autocomplete 
                                  :items="callCounselItems" 
                                  item-text="CD_NM" 
                                  item-value="CD" 
                                  outlined 
                                  hide-details 
                                  class="form-inp table-form-tag" 
                                  placeholder="선택하세요" 
                                  v-model="CALL_COUNSEL">
                                </v-autocomplete>
                              </td>
                              <th>미처리</th>
                              <td>
                                <v-autocomplete 
                                  :items="outStandItems" 
                                  item-text="CD_NM" 
                                  item-value="CD" 
                                  outlined 
                                  hide-details 
                                  class="form-inp table-form-tag" 
                                  placeholder="선택하세요" 
                                  v-model="OUT_STAND">
                                </v-autocomplete>
                              </td>
                            </tr>
                            <tr>
                              <th>설문조사 알림톡</th>
                              <td>
                                <v-autocomplete 
                                  :items="surveyNotiTalkItems" 
                                  item-text="CD_NM" 
                                  item-value="CD" 
                                  outlined 
                                  hide-details 
                                  class="form-inp table-form-tag" 
                                  placeholder="선택하세요" 
                                  v-model="SURVEY_NOTI_TALK">
                                </v-autocomplete>
                              </td>
                              <th>설문조사 취소 알림</th>
                              <td>
                                <v-autocomplete 
                                  :items="surveyCancelNotiTalkItems" 
                                  item-text="CD_NM" 
                                  item-value="CD" 
                                  outlined 
                                  hide-details 
                                  class="form-inp table-form-tag" 
                                  placeholder="선택하세요" 
                                  v-model="SURVEY_CANCEL_NOTI_TALK">
                                </v-autocomplete>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-form>
                  </v-card-text>
                </v-tab-item>

                <v-tab-item ref="tab3Item">
                  <v-card-text>
                    <h2 class="tit-h2 d-flex">
                      <p v-if="!PH_ALLOW_NUM_BOOL" style="color:darkred;">
                        ※ 신규 전화번호가 있습니다<br/>
                        ※ 저장해주세요
                      </p>
                      <div class="ml-auto">
                        <v-btn 
                          outlined 
                          class="btn-default ml-2" 
                          @click="sampleDown('참여 허용된 전화번호 템플릿')"
                        >
                          샘플 다운로드
                        </v-btn>
                        <v-btn outlined class="btn-default ml-2" @click="fileUpload" :disabled="allowBtnBool">
                          업로드
                        </v-btn>
                        <v-btn outlined class="btn-default ml-2" @click="newRowAdd" :disabled="allowBtnBool">
                          신규
                        </v-btn>
                        <v-btn outlined class="btn-etc ml-2" @click="deleteRow" :disabled="allowBtnBool">
                          삭제
                        </v-btn>
                        <v-btn outlined class="btn-point ml-2" @click="saveRow" :disabled="allowBtnBool && PH_ALLOW_NUM_BOOL">
                          저장
                        </v-btn>
                      </div>
                      <v-dialog v-if="dialogCAP0110" max-width="450" content-class="square-modal min-auto" persistent v-model="dialogCAP0110">
                        <dialog-CAP0110
                            headerTitle="참여 허용된 전화번호 신규등록"
                            :param="CAP0110Param"
                            p01
                            @hide="hideDialog('CAP0110')"
                            @submit="submitDialog('CAP0110')"
                        >
                        </dialog-CAP0110>
                      </v-dialog>

                      <v-dialog v-if="dialogCAP0210" max-width="390" content-class="square-modal min-auto" persistent v-model="dialogCAP0210">
                        <dialog-CAP0110
                            headerTitle="참여 허용된 전화번호 엑셀 업로드"
                            :param="CAP0210Param"
                            p02
                            @hide="hideDialog('CAP0210')"
                            @submit="submitDialog2('CAP0210')"
                        >
                        </dialog-CAP0110>
                      </v-dialog>
                    </h2>
                    <v-form ref="subFormOne3" v-model="VALID_SUB_3" lazy-validation>
                      <div class="table-form save-form">
                        <div>
                          <v-data-table
                            dense
                            v-model="gridSelected2"
                            max-height="690px"
                            :search="gridSearch2"
                            fixed-header
                            hide-default-footer
                            :headers="gridDataHeaders2"
                            :items="gridDataText2"
                            :items-per-page="itemsPerPage2"
                            item-key="PHONE_NUMBER"
                            :page.sync="page2"
                            class="grid-default"
                            @page-count="pageCount2 = $event"
                            :item-class="isActiveRow"
                            show-select
                            calculate-widths
                            @click:row="rowClick2"
                            no-data-text="등록된 데이터가 없습니다."
                            id="tab3Table"
                          >
                          <template v-slot:item.PHONE_NUMBER={item}>
                            <div>
                                {{ mixin_setPhoneNo(item.PHONE_NUMBER) }}
                            </div>
                          </template>
                          </v-data-table>
                          <div class="grid-paging text-center pt-2">
                            <v-pagination
                              v-model="page2"
                              :length="pageCount2"
                              :total-visible="totalVisible2"
                              next-icon="svg-paging-next"
                              prev-icon="svg-paging-prev"
                            ></v-pagination>
                          </div>
                        </div>
                      </div>
                    </v-form>
                  </v-card-text>
                </v-tab-item>

              </v-tabs>
            </div>
          </v-form>
        </div>
      </div>
    </template>

  </div>

</template>

<script>
  import axios from "axios"
  import api from "@/store/apiUtil"
  import DialogCAP0110 from "./CAP0110.vue" 

  export default {
    name: "MENU_CAM0100", //name은 'MENU_' + 파일명 조합
    components: {
      DialogCAP0110
    },
    data() {
      return {
        allowBtnBool:true,
        dialogCAP0110:false,
        dialogCAP0210:false,
        formAllDisable:true,
        defaultDis:false,
        CAP0110Param:{},
        CAP0210Param:{},
        imgSrc:'/campaign/manage/images/',
        baseUrl:process.env.VUE_APP_API_URL,
        upImageVtn: false,
        totalCount: 0,
        token: this.mixin_getToken(),
        response: '',
        saveKeysObj:[],
        dropCustKeys: [],
        constructionCompanyNameArr: [],
        constructionCompanyNameFormArr: [],
        businessPlaceArr:[],
        businessPlaceFormArr:[],
        campaignTypeFormArr:[],
        qutnItems:[],

        //파일 관련 데이터
        svcPrvdCd:'',
        relTableCd:'',
        relTypeCdArr:[],

        chatbotTypeFormArr:[],
        statusFormArr:[
          {
            CD:'Y', 'CD_NM':'사용'
          },
          {
            CD:'N', 'CD_NM':'미사용'
          }
        ],
        menu1:false,
        menu2: false,
        menu3: false,
        DATES:['',''],
        DATES_RANGE:'',
        settTimeTxtBool:false,
        camTypeBool:false,
        camTypeBool2:true,
        camTypeBool3:true,

        gridSelected: [],
        gridSelected2: [],
        gridSearch: "",
        gridSearch2: "",
        gridDataHeaders: [
          { text: 'No', align: 'center', value: 'NUM', width: '20px', sortable: true },
          { text: "설문", value: "QUTN_ID", align: "center", width: "60px", sortable: true },
          { text: "현장방문 설문", value: "CMP_QUTN_ID", align: "center", width: "60px", sortable: true },
          { text: "사업지명", value: "BUSS_NAME", align: "center", width: "150px", sortable: true },
          { text: "캠페인명", value: "CMP_NAME", align: "center", width: "150px", sortable: true },
          { text: "캠페인타입", value: "CMP_TYPE_NAME", align: "center", width: "110px", sortable: true },
          { text: "사업지URL", value: "COMP_URL", align: "center", width: "85px", sortable: true },
          { text: "이미지", value: "UPLD_GROUP_ID", align: "center", width: "75px", sortable: true },
          { text: "시작일", value: "CMP_STDT", align: "center", width: "85px", sortable: true },
          { text: "종료일", value: "CMP_ENDT", align: "center", width: "85px", sortable: true },
          { text: "최대예약", value: "MAX_RESERVATION", align: "center", width: "85px", sortable: true },
        ],

        gridDataHeaders2: [
          { text: "No.", value: "ROW_NUM", align: "center", width: "3%", sortable: true },
          { text: "이름", value: "NAME", align: "center", width: "27%", sortable: true },
          { text: "전화번호", value: "PHONE_NUMBER", align: "center", width: "70%", sortable: true },
        ],

        alertMsg: {
          success    : "적용됐습니다.",
          succDel    : "삭제됐습니다.",
          error      : "시스템 장애가 일어났습니다.<br>관리자에게 문의하세요.",
          deleteMsg  : "삭제 하시겠습니까?",
          saveMsg    : "저장 하시겠습니까?",
          newCmpIdEmpty : "신규 저장시 캠페인 상세내용을 저장해주세요.",
          totalChk    : "최대 예약 인원을 초과하였습니다.",
          dateBySettChkMsg : "선택 일자에 일괄 적용하시겠습니까?",
          donDateBySettMsg : "기간에서 일자를 선택해주세요.",
          donDateBySettSuccMsg : "선택된 일자에 일괄 적용이 완료됐습니다.",
          choDatesChkMsg : "일괄 적용할 일자가 존재합니다.\n적용하지 않고 저장하시겠습니까?",
          conComChkMsg : "건설사를 선택해주세요.",
          conBussChkMsg : "사업지를 선택해주세요.",
          delCamMChkMsg : "캠페인을 삭제하시겠습니까?\n(관련된 예약 및 데이터가 삭제됩니다.)",
        },

        gridDataText: [],
        gridDataText2: [],
        excelGridDataText:[],
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
        totalVisible: 15,
        page2: 1,
        pageCount2: 1,
        itemsPerPage2: 100,
        totalVisible2: 15,
        selectedRow: null,
        selectedRows:[],
        CMP_ID:'', //캠페인 관리 ID
        CMP_BOOL:false,
        QUTN_ID:null, //캠페인 설문 ID
        CMP_QUTN_ID:null, //미사전예약자 현장방문 설문 ID
        CMP_RESERV_TIME_SETT_ID:'', //캠페인 예약시간 설정 ID
        CMP_ALIM_SETT_ID:'', //캠페인 알림톡 설정 ID
        CMP_ALLOW_PARTCP_PHONE_NUM_ID:'', //캠페인 참여 허용된 전화번호 설정 ID
        allCodeList:[],
        dayOfItems:[],

        //v-model
        ASP_CUST_KEY:'HLW', //this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
        USER_ID:'',
        GROUP_CD:'',
        USER_AUTH_CHK:false,
        CONSTRUCTION_COMPANY_NAME_SEARCH:'',
        BUSINESS_PLACE_SEARCH:'',
        CAMP_NAME_SEARCH:'',
        CMP_OPEN_DT:new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        CMP_OPEN_TIME:'00:00',
        CMP_END_DT:new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        CMP_END_TIME:'00:00',

        VALID:'',
        BUSINESS_PLACE:'',
        CONSTRUCTION_COMPANY_NAME:'',
        CAMP_NAME:'',
        CAMPAIGN_TYPE:'',
        USE_YN:'',
        MAIN_IMAGE:'',
        CONSULTATION_APPLICATION_IMAGE:'',
        SERVICE_LOGO:'',
        BUTTON_TEXT:'',
        DUPLICATE_PARTICIPATION_STATUS:false,
        FINISHED_LINK:'',
        MAX_RESERVATION:0,
        CHATBOT_TYPE:'',
        UPLD_GROUP_ID:'',
        FILE_KEY_MAIN_IMAGE:'',
        FILE_KEY_CONSULTATION_APPLICATION_IMAGE:'',
        FILE_KEY_SERVICE_LOGO:'',
        VISIT_STR_NUM:1,
        REMA_NUM_PEP_ON_OFF:false,

        RDOnly:false,

        validateRules:{
          constructionCompanyName: 
          [
            v => !!v || '건설사명은 필수입니다.',
          ],
          businessPlaceFormName: [
            v => !!v || '사업지명은 필수입니다.',
          ],
          campaignType: [
            v => !!v || '캠페인타입은 필수입니다.',
          ],
          datesRange: [
            v => !!v || '기간은 필수입니다.',
          ],
          buttonText: [
            v => !!v || '버튼텍스트는 필수입니다.',
          ],
          campName: [
            v => !!v || '캠페인명은 필수입니다.',
          ],
          status: [
            v => !!v || '상태는 필수입니다.',
          ],
        },
        is: {
          campName: false,
          finishedLink: false,
        },

        //sub Tab
        sheetName:[],
        sheetHeader:[],
        sheetData:[],
        example:'',
        // tabs:[],
        resDay:'',
        dayByTimeDisabeld:true,
        dropItemRemove: true,
        currentDayOf:'',
        resDayArr:[],

        placeVisitNotiItems:[],
        placeNotiItems:[],
        visitScheNotiItems:[],
        oneAgoNotiItems:[],
        cancelNotiItems:[],
        appConsultItems:[],
        waitFrontMeItems:[],
        stanNotiItems:[],
        callCounselItems:[],
        outStandItems:[],
        surveyNotiTalkItems:[],
        surveyCancelNotiTalkItems:[],

        //v-model
        VALID_SUB:'',
        VALID_SUB_1:'',
        VALID_SUB_2:'',
        VALID_SUB_3:'',

        TIME_SETT_BTN_BOOL:false,
        ALIM_SETT_BTN_BOOL:false,

        menu2:false,
        DATES2:['', '',],
        CMP_RESERV_TIME_SETT_ID:'',
        TIME_RANGE_LIST:['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00'],
        TIME_RANGE_LIST_BAK:['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00'],
        CNSL_TIME_UNIT:'60',
        CONSULTATION_EXCLUSION_TIME:'',
        TEXT_AREA_DATES:'',
        RESERV_TIME_COLL_APP: false,
        MAX_RESERV_TIME_CNT:30,
        DAY_BY_EXC_TIME:'',
        TOTAL_RESERV_PERS:300,
        DAYS_TOTAL_RESERV_PERS:0,

        PH_ALLOW_NUM_BOOL:true,

        validateRules2:{
            timeRangeList: [
              v => !!v || '시간 범위는 필수입니다.',
            ],
        },

        cnslTimeItems: [
          {text:'06시00분', selected:false, value:'06:00',},
          {text:'07시00분', selected:false, value:'07:00',},
          {text:'08시00분', selected:false, value:'08:00',},
          {text:'09시00분', selected:false, value:'09:00',},
          {text:'10시00분', selected:false, value:'10:00',},
          {text:'11시00분', selected:false, value:'11:00',},
          {text:'12시00분', selected:false, value:'12:00',},
          {text:'13시00분', selected:false, value:'13:00',},
          {text:'14시00분', selected:false, value:'14:00',},
          {text:'15시00분', selected:false, value:'15:00',},
          {text:'16시00분', selected:false, value:'16:00',},
          {text:'17시00분', selected:false, value:'17:00',},
          {text:'18시00분', selected:false, value:'18:00',},
          {text:'19시00분', selected:false, value:'19:00',},
          {text:'20시00분', selected:false, value:'20:00',},
          {text:'21시00분', selected:false, value:'21:00',},
          {text:'22시00분', selected:false, value:'22:00',},
          {text:'23시00분', selected:false, value:'23:00',},
        ],

        cnslTimeItemsBak: [
          {text:'06시00분', selected:false, value:'06:00',},
          {text:'07시00분', selected:false, value:'07:00',},
          {text:'08시00분', selected:false, value:'08:00',},
          {text:'09시00분', selected:false, value:'09:00',},
          {text:'10시00분', selected:false, value:'10:00',},
          {text:'11시00분', selected:false, value:'11:00',},
          {text:'12시00분', selected:false, value:'12:00',},
          {text:'13시00분', selected:false, value:'13:00',},
          {text:'14시00분', selected:false, value:'14:00',},
          {text:'15시00분', selected:false, value:'15:00',},
          {text:'16시00분', selected:false, value:'16:00',},
          {text:'17시00분', selected:false, value:'17:00',},
          {text:'18시00분', selected:false, value:'18:00',},
          {text:'19시00분', selected:false, value:'19:00',},
          {text:'20시00분', selected:false, value:'20:00',},
          {text:'21시00분', selected:false, value:'21:00',},
          {text:'22시00분', selected:false, value:'22:00',},
          {text:'23시00분', selected:false, value:'23:00',},
        ],
        consultationExclusionTimeItems:[],

        CMP_ALIM_SETT_ID:'',
        PLACE_VISIT_NOTI:'',
        PLACE_NOTI:'',
        VISIT_SCHE_NOTI:'',
        ONE_AGO_NOTI:'',
        CANCEL_NOTI:'',
        APP_CONSULT:'',
        WAIT_FRONT_ME:'',
        STAN_NOTI:'',
        CALL_COUNSEL:'',
        OUT_STAND:'',
        SURVEY_NOTI_TALK:'',
        SURVEY_CANCEL_NOTI_TALK:'',
        CHO_DATES:[],
        CHO_DATES_TEXT:'',
        
      }

    },

    async mounted() {
      this.selectParamsSett();
      this.init();
    },

    methods: {
      async init(){
        this.getCommonCode('CAM');
        this.getCommonCode('HLW_REL_TABLE_CD');
        this.getCommonCode('HLW_REL_TYPE_CD');
        this.getCommonCode('HLW_SVC_PRVD_CD');
      },

      fnSurveyBtn(qutnId) {
        const item={
          C_NODE_NO: "CAM0200",
          NODE_TITLE: "설문관리",
          CAM_BOOL:true,
          QUTN_ID:qutnId,
        };
        this.mixin_set_active_tab(item);
      },

      fnTab(num) {
        switch(num) {
          case 1: 
            if(!this.TIME_SETT_BTN_BOOL){

              if(this.CAMPAIGN_TYPE==='TYPE-01'){ //캠페인 타입일 경우
                if(!this.mixin_isEmpty(document.getElementById('resDayDivId'))) {
                  document.getElementById('resDayDivId').innerHTML=''
                  this.cnslTimeItems=this.cnslTimeItemsBak
                }
              }else if(this.CAMPAIGN_TYPE==='TYPE-02'){
                if(!this.mixin_isEmpty(this.DATES[0])&&!this.mixin_isEmpty(this.DATES[1])) {
                  this.getDateRangeData(this.DATES[0], this.DATES[1]) //일자 사이 구간 배열구하기
                }
              }
              
              this.MAX_RESERV_TIME_CNT=30
              this.TOTAL_RESERV_PERS=300
              this.CNSL_TIME_UNIT='60'
              this.TIME_RANGE_LIST=this.TIME_RANGE_LIST_BAK
            }
            break
          case 2:
            if(!this.ALIM_SETT_BTN_BOOL){
              this.CMP_ALIM_SETT_ID=''
              if(!this.mixin_isEmpty(this.$refs.subFormOne2)){
                this.$refs.subFormOne2.reset()
              }
            }
            break
        }
      },

      async selectParamsSett() {
        this.USER_ID = this.user_role.userId;   //유저 ID
        this.GROUP_CD = this.user_role.userGroupCd; //유저 권한

        const pArr = ['HLW_CTCP_CD'];
        this.allCodeList = await this.mixin_common_code_get_all(pArr);

        if (this.GROUP_CD === 'SYSTEMMANAGER') {
          this.USER_AUTH_CHK = true
          this.constructionCompanyNameArr = await this.mixin_common_code_get(this.allCodeList,'HLW_CTCP_CD','')
          this.constructionCompanyNameFormArr = await this.mixin_common_code_get(this.allCodeList,'HLW_CTCP_CD','')
        }else{
          this.USER_AUTH_CHK = false
          let bussCtcpArr = await this.mixin_buss_ctcp_code_user_auto(this.USER_ID)

          for (let index = 0; index < bussCtcpArr.length; index++) {
            const element = bussCtcpArr[index]

            let obj={
              CD:element.CTCP_CODE,
              CD_NM:element.CTCP_NAME
            }

            this.constructionCompanyNameArr.push(obj)
            this.constructionCompanyNameFormArr.push(obj)

            obj={
              CD:element.BUSS_CODE,
              CD_NM:element.BUSS_NAME
            }
            this.businessPlaceArr.push(obj)
          }

          if(!this.mixin_isEmpty(this.constructionCompanyNameArr)) {
            this.CONSTRUCTION_COMPANY_NAME_SEARCH = this.constructionCompanyNameArr[0].CD;
          }

          this.constructionCompanyNameArr.unshift({CD:'', CD_NM:'선택'});
          this.businessPlaceArr.unshift({CD:'', CD_NM:'선택'});
        }
      },

      allowedStep: (m) => m % 1 === 0,

      resViewImg(imgKey) {
        const url=this.baseUrl+'api/file/hlw/images/'+imgKey;
        const headers={
          SERVICE: 'api.file.hlw.images',
          METHOD: 'img',
          TYPE: this.initHeaders.TYPE,
          AUTHORIZATION:this.token,
        };

        axios
        .get(url, {headers:headers})
        .then(response => {
          if (response.data.indexOf('ERROR')===-1) {
            return response.headers;
          }
        }).catch(error => {
          return error.message
        });
      },

      replaceImg(e) {
        e.target.src = require(`@/assets/logo.png`);
      },

      async chkCtcpCd() {
        this.businessPlaceArr=[];

        if(!this.mixin_isEmpty(this.CONSTRUCTION_COMPANY_NAME_SEARCH)) {
          if (this.USER_AUTH_CHK) {
            this.businessPlaceArr = await this.mixin_busscode_get(this.CONSTRUCTION_COMPANY_NAME_SEARCH);
          }else{
            this.businessPlaceArr = await this.mixin_busscode_get(this.CONSTRUCTION_COMPANY_NAME_SEARCH, this.USER_ID);
          }
        }else{
          this.businessPlaceArr = [];
        }

        if(this.businessPlaceArr.length===0) {
          this.BUSINESS_PLACE_SEARCH = '';
        }
      },

      async bussDataChk() {
        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: 'list',
            TYPE: this.initHeaders.TYPE,
          },
          sendData:{}
        };

        requestData.headers["URL"]="/api/hlw/campaign/manage/talk/list";

        // sendData 세팅
        requestData.sendData["BUSS_CODE"]=this.BUSINESS_PLACE;

        let responseGridList = await this.common_postCall(requestData);
        let resHeader = responseGridList.HEADER;
        let resData = responseGridList.DATA;

        if (resHeader.ERROR_FLAG) {
          this.common_alert(this.alertMsg.error, "error");
          return false;
        }else{
          this.placeVisitNotiItems=resData;
          this.placeNotiItems=resData;
          this.visitScheNotiItems=resData;
          this.oneAgoNotiItems=resData;
          this.cancelNotiItems=resData;
          this.appConsultItems=resData;
          this.waitFrontMeItems=resData;
          this.stanNotiItems=resData;
          this.callCounselItems=resData;
          this.outStandItems=resData;
          this.surveyNotiTalkItems=resData;
          this.surveyCancelNotiTalkItems=resData;
        }

      },

      async chkFormCtcpCd() {
        this.businessPlaceFormArr=[];

        if(!this.mixin_isEmpty(this.CONSTRUCTION_COMPANY_NAME)) {
          if (this.USER_AUTH_CHK){
            this.businessPlaceFormArr = await this.mixin_busscode_get(this.CONSTRUCTION_COMPANY_NAME);
          }else{
            this.businessPlaceFormArr = await this.mixin_busscode_get(this.CONSTRUCTION_COMPANY_NAME,this.USER_ID);
          }
        }else{
          this.businessPlaceFormArr = [];
        }

        if(this.businessPlaceFormArr.length===0){
          this.BUSINESS_PLACE='';
        }
      },

      createElFileName() {
        this.FILE_KEY_MAIN_IMAGE = '';
        this.FILE_KEY_CONSULTATION_APPLICATION_IMAGE = '';
        this.FILE_KEY_SERVICE_LOGO = '';

        const $createEl1=document.createElement('h4');
        document.querySelector("[name=mainImage]").closest('td').appendChild($createEl1);

        const $createEl2=document.createElement('h4');
        document.querySelector("[name=serviceLogo]").closest('td').appendChild($createEl2);

        const $createEl3=document.createElement('h4');
        document.querySelector("[name=consultationApplicationImage]").closest('td').appendChild($createEl3);
      },

      getCommonCode(groupCd){
        let reData = [];
        api.post("/api/setting/system/cmmn-code-manage/detail", {
          GROUP_CD: groupCd,
        },
        { 
          head: {
            SERVICE: "setting.system.cmmn-code-manage",
            METHOD: "list",
            TYPE: "BIZ_SERVICE",
          }
        })
        .then((ret) => {
          reData = ret.data.DATA;

          if(groupCd==='CAM'){
            for (let index = 0; index < reData.length; index++) {
              if(reData[index].CD.indexOf('TYPE') !== -1){
                this.campaignTypeFormArr.push(reData[index]);
              }else if(reData[index].CD.indexOf('CHAT') !== -1){
                this.chatbotTypeFormArr.push(reData[index]);
              }
            }
          }else if(groupCd==='HLW_REL_TABLE_CD'){
            for (let index = 0; index < reData.length; index++) {
              if(reData[index].CD_NM.indexOf('캠페인') !== -1){
                this.relTableCd=reData[index].CD;
              }
            }
          }else if(groupCd==='HLW_REL_TYPE_CD'){
            for (let index = 0; index < reData.length; index++) {
              if(reData[index].CD_NM.indexOf('main') !== -1){
                this.relTypeCdArr['mainImage']=reData[index].CD;
              }else if(reData[index].CD_NM.indexOf('consApp') !== -1){
                this.relTypeCdArr['consultationApplicationImage']=reData[index].CD;
              }else if(reData[index].CD_NM.indexOf('serviceLogo') !== -1){
                this.relTypeCdArr['serviceLogo']=reData[index].CD;
              }
            }
          }else if(groupCd==='HLW_SVC_PRVD_CD'){
            for (let index = 0; index < reData.length; index++) {
              if(this.baseUrl.indexOf('local') !== -1){
                if(reData[index].CD_NM.indexOf('local') !== -1){
                  this.svcPrvdCd=reData[index].CD;
                }
              }else{
                if(reData[index].CD_NM.indexOf('local') <= -1){
                  this.svcPrvdCd=reData[index].CD;
                }
              }
            }
          }
        });

      },

      comTypeChange(){
        let tabRefs=[];
        const $refsVal=this.$refs;

        if(this.CAMPAIGN_TYPE==='TYPE-01'){ //캠페인 타입일 경우
          this.settTimeTxtBool=false;
          this.camTypeBool=true;
          this.camTypeBool2=false;
          this.camTypeBool3=true;
          this.VISIT_STR_NUM=1;
          this.REMA_NUM_PEP_ON_OFF=false;
          this.DUPLICATE_PARTICIPATION_STATUS=false;  //중복참여여부

          if(!this.mixin_isEmpty(document.getElementById('resDayDivId'))) {
            document.getElementById('resDayDivId').innerHTML=''; //예약시간 설정 기간 초기화
            this.MAX_RESERV_TIME_CNT=30;
            this.TOTAL_RESERV_PERS=300;
            this.CNSL_TIME_UNIT='60';
            this.TIME_RANGE_LIST=this.TIME_RANGE_LIST_BAK;
            this.cnslTimeItems=this.cnslTimeItemsBak;
          }

        }else if(this.CAMPAIGN_TYPE==='TYPE-02'){ //방문예약 관리일 경우
          this.settTimeTxtBool=true;
          this.camTypeBool=false;
          this.camTypeBool2=true;
          this.camTypeBool3=false;
          this.DUPLICATE_PARTICIPATION_STATUS=false;  //중복참여여부
          this.CHATBOT_TYPE='';

          if(!this.mixin_isEmpty(this.DATES[0])&&!this.mixin_isEmpty(this.DATES[1])) {
            this.getDateRangeData(this.DATES[0], this.DATES[1]); //일자 사이 구간 배열구하기
          }
        }
      },

      formDisabled(boolParam, colorParam){
        var inputs = document.querySelector("form");
        for(let i=0; i<inputs.length; i++){
          if(inputs[i].type === 'text' 
              || inputs[i].type === 'file'
                || inputs[i].type === 'checkbox'
                  || inputs[i].type === 'button'){
            inputs[i].disabled = boolParam;
          }else{
            inputs[i].style.backgroundColor=colorParam;
          }
        }
      },

      deleteInfo() {
        this.common_confirm('삭제하시겠습니까?', this.deleteYes, null, null, null, 'chk');
      },

      async deleteYes() {
        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: 'delete',
            TYPE: this.initHeaders.TYPE,
          },
          sendData:{}
        };

        requestData.headers["URL"]="/api/hlw/campaign/manage/delete";

        // sendData 세팅
        requestData.sendData["CMP_ID"]=this.CMP_ID;
        requestData.sendData["UPLD_GROUP_ID"]=this.UPLD_GROUP_ID;
        requestData.sendData["USE_YN"]='N';
        requestData.sendData["DEL_YN"]='Y';
        requestData.sendData["REG_ID"]=this.USER_ID;

        let responseGridList = await this.common_postCall(requestData);
        let resHeader = responseGridList.HEADER;
        let resData = responseGridList.DATA;

        if (resHeader.ERROR_FLAG) {
          this.common_alert(this.alertMsg.error, "error");
          return false;
        }else{
          this.$refs.form.reset();
          this.imagesName('','','');
          this.actionCallBack();
        }

      },

      fnSearchCamp() {

        if (!this.USER_AUTH_CHK) {
          if(this.mixin_isEmpty(this.CONSTRUCTION_COMPANY_NAME_SEARCH)) {
              this.common_alert(this.alertMsg.conComChkMsg, 'chk');
              return;
          }
          if(this.mixin_isEmpty(this.BUSINESS_PLACE_SEARCH)) {
              this.common_alert(this.alertMsg.conBussChkMsg, 'chk');
              return;
          }
        }

        //허용된 전화번호 버튼
        this.allowBtnBool=true;

        this.searchCampInfo();

        //지정된 데이터 초기화
        this.fnAllClear();
        this.formAllDisable=true;
        this.settTimeTxtBool=false;
        this.camTypeBool=true;
        this.camTypeBool2=true;
        this.camTypeBool3=true;
      },

      async searchCampInfo() {
        this.gridDataText = [];

        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: 'list',
            TYPE: this.initHeaders.TYPE,
          },
          sendData:{}
        };

        requestData.headers["URL"] = "/api/hlw/campaign/manage/list";

        // sendData 세팅
        let constructionCompanyNameSearch=this.mixin_isEmpty(this.CONSTRUCTION_COMPANY_NAME_SEARCH)?'':this.CONSTRUCTION_COMPANY_NAME_SEARCH;
        let businessPlaceSearch=this.mixin_isEmpty(this.BUSINESS_PLACE_SEARCH)?'':this.BUSINESS_PLACE_SEARCH;
        let campNameSearch=this.mixin_isEmpty(this.CAMP_NAME_SEARCH)?'':this.CAMP_NAME_SEARCH;

        requestData.sendData["CONSTRUCTION_COMPANY_NAME_SEARCH"]=constructionCompanyNameSearch;
        requestData.sendData["BUSINESS_PLACE_SEARCH"]=businessPlaceSearch;
        requestData.sendData["CAMP_NAME_SEARCH"]=campNameSearch;
        requestData.sendData["PART_TYPE"]='PO';  // 기존(분양) 타입
        requestData.sendData["DEL_YN"]='N';

        let responseGridList = await this.common_postCall(requestData);
        let resHeader = responseGridList.HEADER;
        let resData = responseGridList.DATA;

        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG) {
          this.common_alert(this.alertMsg.error, "error");
          return false;
        }

        if(resData.length>0){
          this.totalCount=resData[0].TOT_CNT;
        }
        this.gridDataText=resData;
      },

      fnAllClear() {
        this.newAdd(); //상세내용 초기화
        this.fnTabClear(); //탭초기화
      },
      
      fnTabClear() {
        if(!this.mixin_isEmpty(document.getElementById('resDayDivId'))) {
          document.getElementById('resDayDivId').innerHTML=''; //예약시간 설정 기간 초기화
        }
        //알림톡 설정 초기화
        this.CMP_ALIM_SETT_ID='';
        if(!this.mixin_isEmpty(this.$refs.subFormOne2)){
          this.$refs.subFormOne2.reset();
        }
        this.gridDataText2=[]; //허용된 전화번호 탭 초기화
      },

      async allSearchCampInfo(){
        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            TYPE: this.initHeaders.TYPE,
          },
          sendData:{}
        };

        // header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/manage/list";
        requestData.headers["METHOD"] = "list";

        // sendData 세팅
        let constructionCompanyNameSearch=this.CONSTRUCTION_COMPANY_NAME_SEARCH==null?'':this.CONSTRUCTION_COMPANY_NAME_SEARCH;
        let businessPlaceSearch=this.BUSINESS_PLACE_SEARCH==null?'':this.BUSINESS_PLACE_SEARCH;
        let campNameSearch=this.CAMP_NAME_SEARCH==null?'':this.CAMP_NAME_SEARCH;

        requestData.sendData["CONSTRUCTION_COMPANY_NAME_SEARCH"]=constructionCompanyNameSearch;
        requestData.sendData["BUSINESS_PLACE_SEARCH"]=businessPlaceSearch;
        requestData.sendData["CAMP_NAME_SEARCH"]=campNameSearch;

        let responseGridList = await this.common_postCall(requestData);
        let resHeader = responseGridList.HEADER;
        let resData = responseGridList.DATA;

        if (resHeader.ERROR_FLAG) {
          this.common_alert(this.alertMsg.error, "error");
          return false;
        }

        this.totalCount = resData[0].TOT_CNT;
        this.excelGridDataText = resData;
      },

      getDetailListCallBack(response){
        let header = response.HEADER;
        let data = response.DATA;
        if(!this.mixin_isEmpty(response) && !header.ERROR_FLAG){
          if(!this.mixin_isEmpty(data) && data.length > 0) {
            for (let d of data) {
              for (let k of Object.keys(d)) {
                d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k];
              }
              d['index'] = this.gridDataText.length + 1;
              this.gridDataText.push(d);
            }
          }
        }else {
          this.common_alert(this.alertMsg.error, 'error');
        }

      },

      newAdd() {
        this.fnTabClear();
        //버튼 수정/저장 변환
        this.CMP_BOOL=false;
        this.TIME_SETT_BTN_BOOL=false;

        this.createElFileName();
        this.formAllDisable=false;
        this.settTimeTxtBool=false;
        this.camTypeBool=false;
        this.camTypeBool2=false;
        this.camTypeBool3=false;
        this.imagesName('','','');
        this.CMP_ID='';
        this.MAX_RESERVATION=0;
        this.CONSTRUCTION_COMPANY_NAME='';
        this.BUSINESS_PLACE='';
        this.DATES=['',''];
        this.CAMPAIGN_TYPE='';
        this.CAMP_NAME='';
        this.USE_YN='';
        this.MAIN_IMAGE='';
        this.CONSULTATION_APPLICATION_IMAGE='';
        this.SERVICE_LOGO='';
        this.BUTTON_TEXT='';
        this.DUPLICATE_PARTICIPATION_STATUS=false;
        this.FINISHED_LINK='';
        this.CHATBOT_TYPE='';
        this.CMP_OPEN_DT=new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);
        this.CMP_OPEN_TIME='00:00';
        this.CMP_END_DT=new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);
        this.CMP_END_TIME='00:00';

        this.fnSurveyItemsSett();
        this.QUTN_ID=null;
        this.CMP_QUTN_ID=null;
        this.DATES_RANGE='';
        this.REMA_NUM_PEP_ON_OFF=false;
        this.VISIT_STR_NUM=1;
        this.UPLD_GROUP_ID='';
        this.CMP_RESERV_TIME_SETT_ID='';
        this.dayOfItems=[];

        //탭 데이터 초기화
        this.MAX_RESERV_TIME_CNT=30;
        this.TOTAL_RESERV_PERS=300;
        this.CNSL_TIME_UNIT='60';
        this.TIME_RANGE_LIST=this.TIME_RANGE_LIST_BAK;
        this.cnslTimeItems=this.cnslTimeItemsBak;
      },

      async fnSurveyItemsSett() {
        let param = {
          USE_YN:'Y'
          , PART_TYPE:'PO'
          , CTCP_CODE:''
          , BUSS_CODE:''
        };
        this.qutnItems=await this.mixin_survey_get(param);

        this.qutnItems.forEach((element, i) => {
          if(!this.mixin_isEmpty(element.QUTN_NAME)) {
            let strText=element.QUTN_NAME;
            
            strText=strText.replaceAll("&gt;", ">");
            strText=strText.replaceAll("&amp;", "&");
            strText=strText.replaceAll("&quot;", '"');
            strText=strText.replaceAll("&lt;", "<");
            strText=strText.replaceAll("&apos;", "'");
            strText=strText.replaceAll("<br/>", "\r");
            strText=strText.replaceAll("<p>", "\n");
            strText=strText.replaceAll("&#91;", "[");
            strText=strText.replaceAll("&#93;", "]");
            strText=strText.replaceAll("&#40;", "(");
            strText=strText.replaceAll("&#41;", ")");
            strText=strText.replaceAll("&#44;", ",");
            
            this.qutnItems[i].QUTN_NAME=strText;
          }
        });
      },

      camDetReset() {
        var inputs=document.querySelector("form");
        for(let i=0; i<inputs.length; i++){
          if(inputs[i].type === 'text' 
              || inputs[i].type === 'file'
                || inputs[i].type === 'checkbox'
                  || inputs[i].type === 'button'){
            inputs[i].value='';
          }
        }
      },

      imagesName(main, service, consult) {
        const $mainImageQs=document.querySelector("[name=mainImage]").closest('td');
        $mainImageQs.childNodes[2].innerText=main;

        const $serviceImageQs=document.querySelector("[name=serviceLogo]").closest('td');
        $serviceImageQs.childNodes[2].innerText=service;

        const $caImageQs=document.querySelector("[name=consultationApplicationImage]").closest('td');
        $caImageQs.childNodes[2].innerText=consult;
      },

      resetValidation() {
        this.$refs.form.resetValidation();
      },

      sampleDown(fileName) {
        this.sheetData=[];
        this.mixin_common_exportExcel_template(fileName, '참여 허용된 전화번호', ['이름','전화번호'], this.sheetData);
      },

      fileExtTypeChk(fileName){
        let boolParam=false;
        for (let index = 0; index < this.geImgExtArry.length; index++) {
          if(fileName.toLowerCase().indexOf(this.geImgExtArry[index].toLowerCase())!==-1){
            boolParam=true;
          }
        }
        return boolParam;
      },

      finishdAlert() {
        this.common_alert('정상처리 되었습니다.');
      },

      saveCampDetail(){
        if (!this.$refs.form.validate()
          ||!this.DATES[0]
            ||!this.DATES[1]
              ||!this.CMP_OPEN_DT
              ||!this.CMP_END_DT
            ) {
          return;
        }

        this.common_confirm(this.alertMsg.saveMsg, this.registCampDetail, null, null, null, 'chk');
      },

      registCampDetail() {
  /////////////////////////////////////////////////////////////////// 파일 업로드 시작...

        //이미지파일 업로드 및 데이터 전송
        let relTypeCds=[];

        let requestData = {
          headers: {
            SERVICE:'files',
            METHOD:'upload',
            TYPE:this.initHeaders.TYPE,
            URL:this.baseUrl+"api/files/upload",
            AUTHORIZATION:this.token,
            UPLOAD_URI:"/api/files/upload",
            DOWNLOAD_URI:"/api/files/download",
            DIR:this.imgSrc, //파일 저장 경로
            TEMP_DIR:this.imgSrc+"/temp",
            PROC_ID:this.user_role.userId,
            PATH_TYPE:'images',
            REL_ID:this.CMP_ID,
            UPLD_GROUP_ID:this.UPLD_GROUP_ID,
            SVC_PRVD_CD:this.svcPrvdCd,
            REL_TABLE_CD:this.relTableCd,
            'Content-Type':'multipart/form-data',
          },
          sendData:{}
        };

        let vue=this;
        let localSaveKeysObj=[];
        let files=vue.$refs.form.inputs;
        let sknObj={};
        const formData = new FormData();
        for (var index = 0; index < files.length; index++) {
          if(files[index].type === 'file' && files[index].$refs.input.files[0] !== undefined){
            if(this.fileExtTypeChk(files[index].$refs.input.files[0].name)){ //.png,.jpg,.gif,.jpeg........ 이미지 타입 확장자 유무 체크
              formData.append('fileList', files[index].$refs.input.files[0]);
              
              sknObj={};
              sknObj['key']=files[index].$refs.input.name;
              sknObj['fileName']=files[index].$refs.input.files[0].name;
              localSaveKeysObj.push(sknObj); //업로드할 파일 적재

              sknObj={};
              sknObj['key']=files[index].$refs.input.files[0].name;
              sknObj['value']=this.relTypeCdArr[files[index].$refs.input.name];
              relTypeCds.push(sknObj);
            }
          }
        }
        this.saveKeysObj=localSaveKeysObj;

        if(this.saveKeysObj.length>0) {
          formData.append('SVC_PRVD_CD', this.svcPrvdCd);
          formData.append('REL_TABLE_CD', this.relTableCd);
          formData.append('REL_TYPE_CDS', JSON.stringify(relTypeCds));
          formData.append('THUM_IMG_INFO', JSON.stringify({'MAIN_IMAGE':'메인 이미지',"CONSULTATION_APPLICATION_IMAGE":"상담 신청 이미지","SERVICE_LOGO":"서비스 로고"}));

          axios.post(requestData.headers.URL, formData, {
            headers: requestData.headers
          }).then(response => {
            const returnDatas=response.data;
            const retDatas=returnDatas.data;
            if(returnDatas.responseMessage==='정상') { //파일 업로드 정상 후 처리

              for (let index = 0; index < retDatas.length; index++) {
                const element = retDatas[index];
                
                const fileK=element.upldId;
                const upldName=element.upldName;
                const fileGroupK=element.upldGroupId;

                const key=localSaveKeysObj[index].key;
                const fileName=localSaveKeysObj[index].fileName;

                //저장 후 파일별 key가 생성됨
                if(key==='mainImage' && fileName===upldName) {
                  requestData.sendData["FILE_KEY_MAIN_IMAGE"]=fileK;
                }else if(key==='consultationApplicationImage' && fileName===upldName){
                  requestData.sendData["FILE_KEY_CONSULTATION_APPLICATION_IMAGE"]=fileK;
                }else if(key==='serviceLogo' && fileName===upldName){
                  requestData.sendData["FILE_KEY_SERVICE_LOGO"]=fileK;
                }

                //그룹키의 경우 신규 저장시 api 서버에서 생성되나 신규생성이 아닌 수정일 경우 기존 그룹키를 가져와서 셋팅해야함
                requestData.sendData["UPLD_GROUP_ID"]=fileGroupK;
              }
  /////////////////////////////////////////////////////////////////// 파일 업로드 끝...

              this.registDatas(requestData); //파일이 아닌 다른 데이터 저장부분

            }else{
              return this.common_alert(this.alertMsg.error, "error");
            }
            
          }).catch(error => {
            return error.message;
          })
        }else{
          this.registDatas(requestData);
        }
      },

      async registDatas(requestData) {
        requestData.headers= {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: 'regist',
          TYPE: this.initHeaders.TYPE,
          ASYNC: false,
        };

        requestData.headers["URL"] = "/api/hlw/campaign/manage/regist";

        requestData.sendData["CMP_ID"]=this.CMP_ID;
        requestData.sendData["PART_TYPE"]='PO';
        requestData.sendData["CTCP_CODE"]=this.CONSTRUCTION_COMPANY_NAME;
        requestData.sendData["BUSS_CODE"]=this.BUSINESS_PLACE;
        requestData.sendData["CMP_NAME"]=this.CAMP_NAME;
        requestData.sendData["CMP_TYPE_CD"]=this.CAMPAIGN_TYPE;
        requestData.sendData["USE_YN"]=this.USE_YN;
        requestData.sendData["CMP_STDT"]=this.DATES[0];
        requestData.sendData["CMP_ENDT"]=this.DATES[1];
        requestData.sendData["CMP_OPEN_DT"]=this.CMP_OPEN_DT+' '+this.CMP_OPEN_TIME; //챗봇 오픈일자
        requestData.sendData["CMP_END_DT"]=this.CMP_END_DT+' '+this.CMP_END_TIME; //챗봇 마감일자
        requestData.sendData["BTN_NAME"]=this.BUTTON_TEXT;
        if(this.DUPLICATE_PARTICIPATION_STATUS){
          requestData.sendData["DUP_YN"]='Y';
        }else{
          requestData.sendData["DUP_YN"]='N';
        }
        if(this.REMA_NUM_PEP_ON_OFF){ //사전예약시 시간 선택에 예약가능 잔여인원 표시여부
          requestData.sendData["REMA_NUM_PEP_ON_OFF"]='Y';
        }else{
          requestData.sendData["REMA_NUM_PEP_ON_OFF"]='N';
        }
        requestData.sendData["COMP_URL"]=this.FINISHED_LINK;
        requestData.sendData["MAX_RESERVATION"]=this.MAX_RESERVATION;
        requestData.sendData["CHATBOT_TYPE"]=this.CHATBOT_TYPE;
        requestData.sendData["QUTN_ID"]=this.QUTN_ID;
        requestData.sendData["CMP_QUTN_ID"]=this.CMP_QUTN_ID;
        requestData.sendData["VISIT_STR_NUM"]=this.VISIT_STR_NUM; //현장방문 고객 시작 번호
        requestData.sendData["REG_ID"]=this.USER_ID;
        requestData.sendData["UPD_ID"]=this.USER_ID;

        //이미지 KEY
        if(this.mixin_isEmpty(requestData.sendData["FILE_KEY_MAIN_IMAGE"])
          ){
          requestData.sendData["FILE_KEY_MAIN_IMAGE"]=this.FILE_KEY_MAIN_IMAGE;
        }
        if(this.mixin_isEmpty(requestData.sendData["FILE_KEY_CONSULTATION_APPLICATION_IMAGE"])
          ){
          requestData.sendData["FILE_KEY_CONSULTATION_APPLICATION_IMAGE"]=this.FILE_KEY_CONSULTATION_APPLICATION_IMAGE;
        }
        if(this.mixin_isEmpty(requestData.sendData["FILE_KEY_SERVICE_LOGO"])
          ){
          requestData.sendData["FILE_KEY_SERVICE_LOGO"]=this.FILE_KEY_SERVICE_LOGO;
        }
        if(this.mixin_isEmpty(requestData.sendData["UPLD_GROUP_ID"])
          ){
          requestData.sendData["UPLD_GROUP_ID"]=this.UPLD_GROUP_ID;
        }
        
        let responseData = await this.common_postCall(requestData);
        let resHeader = responseData.HEADER;
        let resData = responseData.DATA;

        if (resHeader.ERROR_FLAG) {
          if (!this.mixin_isEmpty(resHeader.MSG)) {
              this.common_alert(resHeader.MSG, "error");
          } else {
              this.common_alert(this.alertMsg.error, "error");
          }
          return false;
        }

        this.CMP_ID=resHeader.CMP_ID;

        //저장 후 리턴 데이터 처리 22.08.11
        this.actionCallBack();

        this.CMP_BOOL=true;
      },

      callNo() {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle:false,
        });
      },

      actionCallBack() {
        this.searchCampInfo();
        this.finishdAlert();
      },

      startDate(e) {
        this.DATES[0]=e;

        //예약시간 설정 하단 폼
        this.defaultDis=false;
        this.MAX_RESERV_TIME_CNT=30;
        this.TOTAL_RESERV_PERS=300;
        this.CNSL_TIME_UNIT='60';

        let newStartDate = this.DATES[0];
        
        if(this.DATES.length>1) {
          let newEndDate = this.DATES[1];

          this.DATES2[0] = newStartDate;
          this.DATES2[1] = newEndDate;

          if(!this.mixin_isEmpty(newStartDate) && !this.mixin_isEmpty(newEndDate)){
            this.getDateRangeData(newStartDate, newEndDate); //일자 사이 구간 배열구하기
            this.DATES_RANGE=newStartDate + ' ~ ' + newEndDate;
          }
        }
      },

      endDate(e) {
        this.DATES[1]=e;

        //예약시간 설정 하단 폼
        this.defaultDis=false;
        this.MAX_RESERV_TIME_CNT=30;
        this.TOTAL_RESERV_PERS=300;
        this.CNSL_TIME_UNIT='60';

        let newStartDate = this.DATES[0];
        let newEndDate = this.DATES[1];

        this.DATES2[0] = newStartDate;
        this.DATES2[1] = newEndDate;

        if(!this.mixin_isEmpty(newStartDate) && !this.mixin_isEmpty(newEndDate)){
          this.getDateRangeData(newStartDate, newEndDate); //일자 사이 구간 배열구하기
          this.DATES_RANGE=newStartDate + ' ~ ' + newEndDate;
        }
      },

      fnClearParams(e) {
        if(e.currentTarget.previousSibling.__vue__.$refs.input.name==='mainImage'){
          const $mainImageQs=document.querySelector("[name=mainImage]").closest('td');
          $mainImageQs.childNodes[2].innerText='';
          this.FILE_KEY_MAIN_IMAGE='';
          this.MAIN_IMAGE='';
        }else if(e.currentTarget.previousSibling.__vue__.$refs.input.name==='consultationApplicationImage'){
          const $caImageQs=document.querySelector("[name=consultationApplicationImage]").closest('td');
          $caImageQs.childNodes[2].innerText='';
          this.FILE_KEY_CONSULTATION_APPLICATION_IMAGE='';
          this.CONSULTATION_APPLICATION_IMAGE='';
        }else if(e.currentTarget.previousSibling.__vue__.$refs.input.name==='serviceLogo'){
          const $serviceImageQs=document.querySelector("[name=serviceLogo]").closest('td');
          $serviceImageQs.childNodes[2].innerText='';
          this.FILE_KEY_SERVICE_LOGO='';
          this.SERVICE_LOGO='';
        }
      },
      
      fnRowClick(item) {
        this.CMP_BOOL=true;

        this.MAIN_IMAGE='';
        this.CONSULTATION_APPLICATION_IMAGE='';
        this.SERVICE_LOGO='';

        //설문 아이템 재셋팅
        this.fnSurveyItemsSett();

        this.formAllDisable=false;
        this.createElFileName();

        this.CMP_ID=item.CMP_ID;
        this.QUTN_ID=item.QUTN_ID;
        this.CMP_QUTN_ID=item.CMP_QUTN_ID;
        this.CONSTRUCTION_COMPANY_NAME=item.CTCP_CODE;

        this.chkFormCtcpCd();
        this.BUSINESS_PLACE=item.BUSS_CODE;
        if(!this.mixin_isEmpty(this.BUSINESS_PLACE)){
          this.bussDataChk();
        }
        this.CAMPAIGN_TYPE=item.CMP_TYPE_CD;
        this.comTypeChange();

        this.CAMP_NAME=item.CMP_NAME;
        this.USE_YN=item.USE_YN;

        this.imagesName(item.MAIN_IMAGE_NAME,item.SERVICE_LOGO_NAME,item.CONSULTATION_APPLICATION_IMAGE_NAME);
      
        this.BUTTON_TEXT=item.BTN_NAME;
        if(item.DUP_YN==='Y'){
          this.DUPLICATE_PARTICIPATION_STATUS=true;
        }else{
          this.DUPLICATE_PARTICIPATION_STATUS=false;
        }
        this.FINISHED_LINK=item.COMP_URL;
        this.MAX_RESERVATION=String(item.MAX_RESERVATION).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        this.CHATBOT_TYPE=item.CHATBOT_TYPE;

        if(item.REMA_NUM_PEP_ON_OFF=='Y'){
          this.REMA_NUM_PEP_ON_OFF=true;
        }else{
          this.REMA_NUM_PEP_ON_OFF=false;
        }
        this.VISIT_STR_NUM=this.mixin_isEmpty(item.VISIT_STR_NUM)?0:item.VISIT_STR_NUM;

        if(!this.mixin_isEmpty(item.CMP_OPEN_DT) && item.CMP_OPEN_DT!=='0000-00-00 00:00'){
          const cmpOpenDt=item.CMP_OPEN_DT.split(' ');
          this.CMP_OPEN_DT=cmpOpenDt[0];
          this.CMP_OPEN_TIME=cmpOpenDt[1];
        }else{
          this.CMP_OPEN_DT=new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);
          this.CMP_OPEN_TIME='00:00';
        }

        if(!this.mixin_isEmpty(item.CMP_END_DT) && item.CMP_END_DT!=='0000-00-00 00:00'){
          const cmpEndDt=item.CMP_END_DT.split(' ');
          this.CMP_END_DT=cmpEndDt[0];
          this.CMP_END_TIME=cmpEndDt[1];
        }else{
          this.CMP_END_DT=new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);
          this.CMP_END_TIME='00:00';
        }
        
        this.UPLD_GROUP_ID=item.UPLD_GROUP_ID;
        this.FILE_KEY_MAIN_IMAGE=item.FILE_KEY_MAIN_IMAGE;
        this.FILE_KEY_CONSULTATION_APPLICATION_IMAGE=item.FILE_KEY_CONSULTATION_APPLICATION_IMAGE;
        this.FILE_KEY_SERVICE_LOGO=item.FILE_KEY_SERVICE_LOGO;

        //subTab1 setting
        this.dayOfItems=[];

        //탭 데이터 초기화
        this.MAX_RESERV_TIME_CNT=30;
        this.TOTAL_RESERV_PERS=300;
        this.CNSL_TIME_UNIT='60';
        this.TIME_RANGE_LIST=this.TIME_RANGE_LIST_BAK;
        this.cnslTimeItems=this.cnslTimeItemsBak;
        this.RESERV_TIME_COLL_APP=false;

        if(this.CAMPAIGN_TYPE==='TYPE-02'){ //캠페인 타입이 방문예약일 경우만
          this.tab1ItemSett(this.CMP_ID);
          this.settTimeTxtBool=true;
          this.camTypeBool=false;
          this.camTypeBool2=true;
          this.camTypeBool3=false;
        }else if(this.CAMPAIGN_TYPE==='TYPE-01'){
          this.TIME_SETT_BTN_BOOL=false;
          this.settTimeTxtBool=false;
          this.camTypeBool=true;
          this.camTypeBool2=false;
          this.camTypeBool3=true;
          if(!this.mixin_isEmpty(document.getElementById('resDayDivId'))) {
            document.getElementById('resDayDivId').innerHTML='';
          }
        }

        //subTab2 setting
        this.tab2ItemSett(this.CMP_ID);

        //subTab3 setting
        this.tab3ItemSett(this.CMP_ID);
        this.PH_ALLOW_NUM_BOOL=true;

        this.DATES[0]=item.CMP_STDT;
        this.DATES[1]=item.CMP_ENDT;

        if(!this.mixin_isEmpty(this.DATES[0])&&!this.mixin_isEmpty(this.DATES[1])) {
          this.DATES_RANGE=this.DATES[0] + ' ~ ' + this.DATES[1];
        }
        
      },

      async tab1ItemSett(param) {
        
        if(!this.mixin_isEmpty(document.getElementById('resDayDivId'))) {
          document.getElementById('resDayDivId').innerHTML='';
        }

        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: 'data',
            TYPE: this.initHeaders.TYPE,
          },
          sendData:{}
        };

        requestData.headers["URL"] = "/api/hlw/campaign/manage/resTimeSett/data";

        // sendData 세팅
        requestData.sendData["CMP_ID"] = param;

        let responseGridList = await this.common_postCall(requestData);
        let resHeader = responseGridList.HEADER;
        let resData = responseGridList.DATA;
        let resDatas = responseGridList.DATAS;

        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG) {
          this.common_alert(this.alertMsg.error, "error");
          return false;
        }

        if(resData.length>0){
          resData=resData[0];
          this.DATES2[0]=resData.RESERV_DAY_STDT;
          this.DATES2[1]=resData.RESERV_DAY_ENDT;
          this.CMP_RESERV_TIME_SETT_ID=resData.CMP_RESERV_TIME_SETT_ID;
          this.TIME_SETT_BTN_BOOL=true;

          const regDatasCnt=resDatas.length;
          if(regDatasCnt > 0) {
            this.getDateRangeData(resDatas[0].RESERV_DAY, resDatas[regDatasCnt-1].RESERV_DAY);
            this.dayOfItems=[];
    
            for (let index = 0; index < regDatasCnt; index++) {
              const element = resDatas[index];
              if(element.RESERV_DAY!==""&&element.RESERV_DAY!==undefined){
                let item={
                  RESERV_DAY:element.RESERV_DAY,
                  TIME_RANGE_LIST:element.TIME_RANGE_LIST,
                  MAX_RESERV_TIME_CNT:element.MAX_RESERV_TIME_CNT,
                  CNSL_TIME_UNIT:element.CNSL_TIME_UNIT,
                  TOTAL_RESERV_PERS:element.TOTAL_RESERV_PERS,
                };
                this.dayOfItems[element.RESERV_DAY]=item;
    
                //초기셋팅 60분이 아닐 경우
                if(element.CNSL_TIME_UNIT!=='60'&&element.CNSL_TIME_UNIT!==60){
                  this.fnCnslTimeItemsSett(element.RESERV_DAY, element.CNSL_TIME_UNIT);
                }
              }
            }
          }
          

        }else{
          this.getDateRangeData(this.DATES[0], this.DATES[1]); //일자 사이 구간 배열구하기
          this.CNSL_TIME_UNIT=60;
          this.TIME_SETT_BTN_BOOL=false;
        }
      },

      fnCnslTimeItemsSett(reservDay, cnslTimeUnit) {
        cnslTimeUnit=Number(cnslTimeUnit);
        this.cnslTimeItems=[];
        const maxMinu=60*18;
        let timeRange=maxMinu/cnslTimeUnit;
        let choosDay=new Date(reservDay+' '+'06:00');	// 현재 날짜 및 시간
        for(let i = 0; i < timeRange; i++){
          const hour=choosDay.getHours()>9?''+choosDay.getHours():'0'+choosDay.getHours();
          const minute=choosDay.getMinutes()>9?''+choosDay.getMinutes():'0'+choosDay.getMinutes();

          const timeText=hour+'시'+minute+"분";
          const timeValue=hour+':'+minute;

          const cnslTimeItemObj={
            text: timeText,
            selected: false,
            value: timeValue,
          };
          this.cnslTimeItems.push(cnslTimeItemObj);
          choosDay.setMinutes(choosDay.getMinutes()+cnslTimeUnit);
        }
      },

      async tab2ItemSett(param) {
        //탭 데이터 초기화
        if(this.$refs.subFormOne2!==undefined){
          this.$refs.subFormOne2.reset();
        }

        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: 'data',
            TYPE: this.initHeaders.TYPE,
          },
          sendData:{}
        };

        requestData.headers["URL"] = "/api/hlw/campaign/manage/alimTalkSett/data";

        // sendData 세팅
        requestData.sendData["CMP_ID"] = param;

        let responseGridList = await this.common_postCall(requestData);
        let resHeader = responseGridList.HEADER;
        let resData = responseGridList.DATA;

        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG) {
          this.common_alert(this.alertMsg.error, "error");
          return false;
        }

        if(resData.length>0){
          resData=resData[0];
          this.ALIM_SETT_BTN_BOOL=true;
          this.CMP_ALIM_SETT_ID=resData.CMP_ALIM_SETT_ID;
          this.VISIT_SCHE_NOTI=resData.VISIT_SCHE_NOTI;
          this.ONE_AGO_NOTI=resData.ONE_AGO_NOTI;
          this.CANCEL_NOTI=resData.CANCEL_NOTI;
          this.APP_CONSULT=resData.APP_CONSULT;
          this.WAIT_FRONT_ME=resData.WAIT_FRONT_ME;
          this.STAN_NOTI=resData.STAN_NOTI;
          this.CALL_COUNSEL=resData.CALL_COUNSEL;
          this.OUT_STAND=resData.OUT_STAND;
          this.SURVEY_NOTI_TALK=resData.SURVEY_NOTI_TALK;
          this.SURVEY_CANCEL_NOTI_TALK=resData.SURVEY_CANCEL_NOTI_TALK;
          this.PLACE_VISIT_NOTI=resData.PLACE_VISIT_NOTI;
          this.PLACE_NOTI=resData.PLACE_NOTI;
        }else{
          this.ALIM_SETT_BTN_BOOL=false;
          this.CMP_ALIM_SETT_ID='';
          if(!this.mixin_isEmpty(this.$refs.subFormOne2)){
            this.$refs.subFormOne2.reset();
          }
        }
      },

      async tab3ItemSett(param) {
        //탭 데이터 초기화
        this.gridDataText2=[];
        this.gridSelected2=[];

        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: 'list',
            TYPE: this.initHeaders.TYPE,
          },
          sendData:{}
        };

        requestData.headers["URL"] = "/api/hlw/campaign/manage/allowPartcpPhoneNum/list";

        // sendData 세팅
        requestData.sendData["CMP_ID"] = param;

        let responseGridList = await this.common_postCall(requestData);
        let resHeader = responseGridList.HEADER;
        let resData = responseGridList.DATA;

        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG) {
          this.common_alert(this.alertMsg.error, "error");
          return false;
        }else{
          this.allowBtnBool=false;
          if(resData.length>0){
            this.gridDataText2=resData;
          }else{
            this.gridDataText2=[];
          }
        }

      },

      rowClick2: function (item, row) {
        const rowItem = row.item; // just temporary
        const rowNo = this.selectedRows.indexOf(rowItem);
        if (rowNo == -1) {
          row.select(true);
          this.selectedRows.push(rowItem);
        } else {
          this.selectedRows.splice(rowNo, 1);
          row.select(false);
        }
      },

      isActiveRow(item) {
        this.selectedRow = item;
        return item.CMP_ID === this.CMP_ID ? "active" : "";
      },

      //sub Tab
      startDate2(e) {
        this.DATES2[0]=e;
      },
      
      endDate2(e) {
        this.DATES2[1]=e;
      },

      fnDateBySett() {
        if(!this.mixin_isEmpty(this.CHO_DATES)) {
          this.common_confirm(this.alertMsg.dateBySettChkMsg, this.fnDateAllSett, null, null, null, 'chk');
        }else{
          this.common_alert(this.alertMsg.donDateBySettMsg, 'chk');
        }
      },

      fnDateAllSett() {
        this.CHO_DATES.forEach((ele, idx) => {
          this.fnDayItemSett(ele);
        });

        this.resDayArr.forEach((ele, idx) => {
          const btnId=document.getElementById('datesBtn'+idx);
          if(btnId!==null&&btnId!==undefined){
            btnId.style.background='';
            btnId.style.border='';
          }
        });

        this.CHO_DATES=[]; //선택된 일자 일괄 적용후 초기화

        this.common_toast(this.alertMsg.donDateBySettSuccMsg, 'success');
      },

      saveCampDetailSubTab1(){
        if(this.CMP_ID===''){
          this.common_alert(this.alertMsg.newCmpIdEmpty, "chk");
          return;
        }
        if (!this.$refs.subFormOne1.validate()) {
          return;
        }

        let saveMsg='';
        if(!this.mixin_isEmpty(this.CHO_DATES)) {
          saveMsg=this.alertMsg.choDatesChkMsg;
        }else{
          saveMsg=this.alertMsg.saveMsg;
        }
        
        this.common_confirm(saveMsg, this.saveYesSubTab1, null, null, null, 'chk');
      },

      async saveYesSubTab1(){
        //저장로직
        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: 'regist',
            TYPE: this.initHeaders.TYPE,
          },
          sendData:{}
        };

        requestData.headers["URL"]="/api/hlw/campaign/manage/resTimeSett/regist";

        // sendData 세팅
        requestData.sendData["CMP_RESERV_TIME_SETT_ID"]=this.CMP_RESERV_TIME_SETT_ID;
        requestData.sendData["CMP_ID"]=this.CMP_ID;
        requestData.sendData["QUTN_ID"]=this.QUTN_ID;
        requestData.sendData["RESERV_DAY_STDT"]=this.DATES[0];
        requestData.sendData["RESERV_DAY_ENDT"]=this.DATES[1];
        requestData.sendData["DAYS_TOTAL_RESERV_PERS"]=this.TOTAL_RESERV_PERS;

        let dayItems=[];
        for (let index = 0; index < this.resDayArr.length; index++) {
          const element=this.resDayArr[index];
          dayItems.push(this.dayOfItems[element]);
        }

        requestData.sendData["DAY_ITEMS"]=dayItems;
        requestData.sendData["UPD_ID"]=this.USER_ID;
        requestData.sendData["REG_ID"]=this.USER_ID;

        let responseGridList = await this.common_postCall(requestData);
        let resHeader = responseGridList.HEADER;
        let resData = responseGridList.DATA;

        if (resHeader.ERROR_FLAG) {
          this.common_alert(this.alertMsg.error, "error");
          return false;
        }else{
          this.RESERV_TIME_COLL_APP=false;
          this.TIME_SETT_BTN_BOOL=true;
          this.tab1ItemSett(this.CMP_ID);
          this.finishdAlert();
        }

      },

      saveCampDetailSubTab2(){
        if(this.CMP_ID===''){
          this.common_alert(this.alertMsg.newCmpIdEmpty, "error");
          return;
        }

        if(
          this.mixin_isEmpty(this.PLACE_VISIT_NOTI)&&
          this.mixin_isEmpty(this.PLACE_NOTI)&&
          this.mixin_isEmpty(this.VISIT_SCHE_NOTI)&&
          this.mixin_isEmpty(this.ONE_AGO_NOTI)&&
          this.mixin_isEmpty(this.CANCEL_NOTI)&&
          this.mixin_isEmpty(this.APP_CONSULT)&&
          this.mixin_isEmpty(this.WAIT_FRONT_ME)&&
          this.mixin_isEmpty(this.STAN_NOTI)&&
          this.mixin_isEmpty(this.CALL_COUNSEL)&&
          this.mixin_isEmpty(this.OUT_STAND)&&
          this.mixin_isEmpty(this.SURVEY_NOTI_TALK)&&
          this.mixin_isEmpty(this.SURVEY_CANCEL_NOTI_TALK)
        ){
          return;
        }

        this.common_confirm(this.alertMsg.saveMsg, this.saveYesSubTab2, null, null, null, 'chk');
      },

      async saveYesSubTab2(){
        //저장로직
        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: 'regist',
            TYPE: this.initHeaders.TYPE,
          },
          sendData:{}
        };

        requestData.headers["URL"] = "/api/hlw/campaign/manage/talk/regist";

        requestData.sendData["CMP_ALIM_SETT_ID"]=this.CMP_ALIM_SETT_ID;
        requestData.sendData["CMP_ID"]=this.CMP_ID;
        requestData.sendData["QUTN_ID"]=this.QUTN_ID;
        requestData.sendData["PLACE_VISIT_NOTI"]=this.PLACE_VISIT_NOTI;
        requestData.sendData["PLACE_NOTI"]=this.PLACE_NOTI;
        requestData.sendData["VISIT_SCHE_NOTI"]=this.VISIT_SCHE_NOTI;
        requestData.sendData["ONE_AGO_NOTI"]=this.ONE_AGO_NOTI;
        requestData.sendData["CANCEL_NOTI"]=this.CANCEL_NOTI;
        requestData.sendData["APP_CONSULT"]=this.APP_CONSULT;
        requestData.sendData["WAIT_FRONT_ME"]=this.WAIT_FRONT_ME;
        requestData.sendData["STAN_NOTI"]=this.STAN_NOTI;
        requestData.sendData["CALL_COUNSEL"]=this.CALL_COUNSEL;
        requestData.sendData["OUT_STAND"]=this.OUT_STAND;
        requestData.sendData["SURVEY_NOTI_TALK"]=this.SURVEY_NOTI_TALK;
        requestData.sendData["SURVEY_CANCEL_NOTI_TALK"]=this.SURVEY_CANCEL_NOTI_TALK;
        requestData.sendData["REG_ID"]=this.USER_ID;
        requestData.sendData["UPD_ID"]=this.USER_ID;
        
        let responseData = await this.common_postCall(requestData);
        let resHeader = responseData.HEADER;
        let resData = responseData.DATA;

        if (resHeader.ERROR_FLAG) {
          this.common_alert(this.alertMsg.error, "error");
          return false;
        }

        if(resData.length>0){
          resData=resData[0];
          this.CMP_ALIM_SETT_ID=resData.CMP_ALIM_SETT_ID;
        }

        this.finishdAlert();
      },

      fnDayItemSett(currentDayOf) {
        if(!this.mixin_isEmpty(currentDayOf)) {

          let cnslTimeUnit='60';
          if(!this.mixin_isEmpty(this.CNSL_TIME_UNIT)) {
            cnslTimeUnit=this.CNSL_TIME_UNIT;
          }

          let maxReservTimeCnt=30;
          if(!this.mixin_isEmpty(this.MAX_RESERV_TIME_CNT)&&this.MAX_RESERV_TIME_CNT>0) {
            maxReservTimeCnt=this.MAX_RESERV_TIME_CNT;
          }

          let item={
            RESERV_DAY:currentDayOf,
            TIME_RANGE_LIST:this.TIME_RANGE_LIST,
            MAX_RESERV_TIME_CNT:maxReservTimeCnt,
            CNSL_TIME_UNIT:cnslTimeUnit,
            TOTAL_RESERV_PERS:this.TOTAL_RESERV_PERS,
          };
          this.dayOfItems[currentDayOf]=item;
        }
      },

      // 기간 날짜 클릭시 실행되는 함수
      fnDayTimeSett(event) {
        //예약 시간 설정 하단 폼
        this.defaultDis=false;

        if(!this.RESERV_TIME_COLL_APP) {
          for (let i = 0; i < this.resDayArr.length; i++) {
            const btnId=document.getElementById('datesBtn'+i);
            btnId.style.background='';
            btnId.style.border='';
    
            if(this.mixin_isEmpty(this.dayOfItems[this.resDayArr[i]])) {
              this.TIME_SETT_BTN_BOOL=false;
              this.TIME_RANGE_LIST=this.TIME_RANGE_LIST_BAK;
              this.fnDayItemSett(this.resDayArr[i]);
            }
          }
    
          event.target.style.background='#EA6472';
          event.target.style.border='2px solid #e1d69e';
          event.target.style.cursor='pointer';
          this.currentDayOf=event.currentTarget.innerText;
    
          if(!this.mixin_isEmpty(this.dayOfItems[this.currentDayOf])) {
            this.MAX_RESERV_TIME_CNT=this.dayOfItems[this.currentDayOf].MAX_RESERV_TIME_CNT;
            this.RESERV_DAY=this.dayOfItems[this.currentDayOf].RESERV_DAY;
            this.TOTAL_RESERV_PERS=this.dayOfItems[this.currentDayOf].TOTAL_RESERV_PERS;
            this.CNSL_TIME_UNIT=this.dayOfItems[this.currentDayOf].CNSL_TIME_UNIT;
    
            // 시간 선택박스 재셋팅
            this.fnCnslTimeItemsSett(this.currentDayOf, this.CNSL_TIME_UNIT);
    
            let cnslStTime=this.dayOfItems[this.currentDayOf].TIME_RANGE_LIST;
            if(!Array.isArray(cnslStTime)) {
              cnslStTime=cnslStTime.split(',');
            }
            this.TIME_RANGE_LIST=cnslStTime;
          }
          this.fnDayItemSett(this.currentDayOf);

        }else{

          if(this.CHO_DATES.length==0) {
            this.CHO_DATES.push(event.currentTarget.innerText);
            event.target.style.background='#EA6472';
            event.target.style.cursor='pointer';
          }else{
            const choDateIdx=this.CHO_DATES.indexOf(event.currentTarget.innerText);
            if(choDateIdx<0) {
              this.CHO_DATES.push(event.currentTarget.innerText);
              event.target.style.background='#EA6472';
              event.target.style.cursor='pointer';
            }else{
              this.CHO_DATES.splice(choDateIdx, 1);
              event.target.style.background='';
              event.target.style.border='';
            }
          }

        }

        const chkUnitTimesLeng=this.TIME_RANGE_LIST.length;
        let maxResPers=this.MAX_RESERV_TIME_CNT*chkUnitTimesLeng; //*this.resDayArr.length
        this.TOTAL_RESERV_PERS=String(maxResPers).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },

      getDateRangeData(stDate, endDate) {  //param1은 시작일, param2는 종료일이다.
        if(this.CAMPAIGN_TYPE==='TYPE-02'&&!this.mixin_isEmpty(document.getElementById('resDayDivId'))){
          document.getElementById('resDayDivId').innerHTML='';
          this.resDayArr=[];
          let dates=[];
          const ssDay = new Date(stDate);
          const eeDay = new Date(endDate);
          while(ssDay.getTime() <= eeDay.getTime()) {
            let mon = (ssDay.getMonth()+1);
            mon = mon < 10 ? '0'+mon : mon;
            let day = ssDay.getDate();
            day = day < 10 ? '0'+day : day;
            dates.push(ssDay.getFullYear() + '-' + mon + '-' +  day);
            ssDay.setDate(ssDay.getDate() + 1);
          }

          for (let index = 0; index < dates.length; index++) {
            const RESERV_DAY=dates[index];
            this.resDayArr.push(RESERV_DAY);

            let btnTag=document.createElement('button');
            btnTag.type='button';
            btnTag.id='datesBtn'+index;
            btnTag.className='ml-3';
            btnTag.innerText=RESERV_DAY;
            btnTag.style.marginBottom='2px';

            document.getElementById('resDayDivId').appendChild(btnTag);
          }

          this.btnAttSett(dates);
        }
      },

      btnAttSett(dates) {
        for (let i = 0; i < dates.length; i++) {
          const btnId=document.getElementById('datesBtn'+i);
          if(btnId!==null&&btnId!==undefined){
            btnId.addEventListener('click', this.fnDayTimeSett);
          }
        }

        if(!this.mixin_isEmpty(document.getElementById('datesBtn0')) && !this.RESERV_TIME_COLL_APP) {
          setTimeout(() => document.getElementById('datesBtn0').click(), 333);
        }
      },

      calculFunc() {
        const chkUnitTimesLeng=this.TIME_RANGE_LIST.length;
        let maxResPers=this.MAX_RESERV_TIME_CNT*chkUnitTimesLeng; //*this.resDayArr.length
        this.TOTAL_RESERV_PERS=String(maxResPers).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if(!this.RESERV_TIME_COLL_APP) { //일괄적용여부
          this.fnDayItemSett(this.currentDayOf);
        }
      },

      totResPersCal() {
        if(!this.RESERV_TIME_COLL_APP) { //일괄 적용여부 false
          if(this.resDayArr.length>0){
            let daysTotalReservPers=0;
            for (let index = 0; index < this.resDayArr.length; index++) {
              const element = this.resDayArr[index];
              if(!this.mixin_isEmpty(this.dayOfItems[element])){
                let totalReservPers=this.dayOfItems[element].TOTAL_RESERV_PERS;
                if(typeof(totalReservPers)=='string' && totalReservPers.indexOf(',')!==-1){
                  totalReservPers=totalReservPers.replace(',', '');
                }
                daysTotalReservPers+=Number(totalReservPers);
              }
            }

            if(this.CAMPAIGN_TYPE==='TYPE-02') {
              if(daysTotalReservPers>this.MAX_RESERVATION.replace(',', '')){
                this.maxTotResPerModi(daysTotalReservPers);
              }
            }
          }
        }else{
          //일괄 적용여부 true
          if(this.resDayArr.length>0){
            const chkUnitTimesLeng=this.TIME_RANGE_LIST.length;
            let maxResPers=this.MAX_RESERV_TIME_CNT*chkUnitTimesLeng; //*this.resDayArr.length
            this.MAX_RESERVATION=String(maxResPers*this.resDayArr.length).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        }
      },

      maxTotResPerModi(daysTotalReservPers) {
        this.MAX_RESERVATION=String(daysTotalReservPers).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.callNo();
      },

      cnslTimeChkFn(event) {

        let cnslTimeUnit=0;
        if(event.currentTarget!==null&&event.currentTarget!==undefined) {
          cnslTimeUnit=event.currentTarget.value;
        }else if(event){
          cnslTimeUnit=event;
        }else{
          cnslTimeUnit=this.CNSL_TIME_UNIT;
        }
        cnslTimeUnit=Number(cnslTimeUnit);

        this.cnslTimeItems=[];

        if(!this.mixin_isEmpty(cnslTimeUnit)&&cnslTimeUnit>0&&!this.mixin_isEmpty(this.currentDayOf)){
          
          const maxMinu=60*18;
          let visiMinu=maxMinu/cnslTimeUnit;
          let timeRange=visiMinu;

          let choosDay = new Date(this.currentDayOf+' '+'06:00');	// 현재 날짜 및 시간

          let stTimeNum=9;
          if(this.TIME_RANGE_LIST.length>0){
            stTimeNum=Number(this.TIME_RANGE_LIST[0].replaceAll(':','').substring(0,2));
          }

          let edTimeNum=18;
          if(this.TIME_RANGE_LIST.length>0){
            edTimeNum=Number(this.TIME_RANGE_LIST[this.TIME_RANGE_LIST.length-1].replaceAll(':','').substring(0,2));
          }
          
          let timeRangeList=[];
          let cnt=0;
          for(let i = 0; i < timeRange; i++){

            const hour=choosDay.getHours()>9?''+choosDay.getHours():'0'+choosDay.getHours();
            const minute=choosDay.getMinutes()>9?''+choosDay.getMinutes():'0'+choosDay.getMinutes();

            const timeText=hour+'시'+minute+"분";
            const timeValue=hour+':'+minute;

            const cnslTimeItemObj={
              text: timeText,
              selected: false,
              value: timeValue,
            };
            this.cnslTimeItems.push(cnslTimeItemObj);

            if(stTimeNum<=choosDay.getHours()&&edTimeNum>=choosDay.getHours()){
              timeRangeList.push(timeValue);
              cnt++;
            }
            choosDay.setMinutes(choosDay.getMinutes()+cnslTimeUnit);
          }
          this.TIME_RANGE_LIST=timeRangeList;
        }
        this.calculFunc();
      },

      chkTimeRangeListFn() {
        this.TIME_RANGE_LIST.sort();

        if(!this.RESERV_TIME_COLL_APP) { //일괄적용여부
          if(!this.mixin_isEmpty(this.dayOfItems[this.currentDayOf])){
            this.dayOfItems[this.currentDayOf].TIME_RANGE_LIST=this.TIME_RANGE_LIST;
          }
        }

        const chkUnitTimesLeng=this.TIME_RANGE_LIST.length;
        let maxResPers=this.MAX_RESERV_TIME_CNT*chkUnitTimesLeng; //*this.resDayArr.length
        this.TOTAL_RESERV_PERS=String(maxResPers).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.totResPersCal();
      },

      removeDropTime(item) {
        let timeArr=this.dayOfItems[this.currentDayOf].TIME_RANGE_LIST;
        this.dayOfItems[this.currentDayOf].TIME_RANGE_LIST=[];
        this.TIME_RANGE_LIST=[];
        for(let i = 0; i < timeArr.length; i++){
          if( (timeArr[i] != "" || timeArr[i] != null)
              && item.value!==timeArr[i] ){
                this.TIME_RANGE_LIST.push(timeArr[i]);
                this.dayOfItems[this.currentDayOf].TIME_RANGE_LIST.push(timeArr[i]);
          }
        }

        this.calculFunc();

        if(this.RESERV_TIME_COLL_APP) {
          this.totResPersCal();
        }
      },

      newRowAdd() {
        if(this.CMP_ID===''){
          this.common_alert(this.alertMsg.newCmpIdEmpty, "error");
          return;
        }
        this.CAP0110Param={
          headerTitle: "참여 허용된 전화번호 신규등록",
          data:[],
          type:"I",
          rowNum:this.gridDataText2.length
        };

        this.showDialog('CAP0110');
      },

      showDialog(type) {
        this[`dialog${type}`] = true;
      },

      hideDialog(type) {
        this[`dialog${type}`] = false;
      },
      
      submitDialog(type) {

        this.gridSelected2=[];
        for (let index=0; index < this.CAP0110Param.data.length; index++) {
          const element=this.CAP0110Param.data[index];

          let rowDataChk=true;
          if(this.gridDataText2.length>0){
            for (let i = 0; i < this.gridDataText2.length; i++) {
              const rowData = this.gridDataText2[i].PHONE_NUMBER.replaceAll(/[-]/gi, '');
              if(element.PHONE_NUMBER.replaceAll(/[-]/gi, '')===rowData){
                rowDataChk=false;
                
                this.common_alert('중복된 전화번호입니다.', "error");
                return;
              }
            }
          }

          if(rowDataChk){
            this.gridDataText2.unshift(element);
          }
        }
        
        this.hideDialog(type);

        this.PH_ALLOW_NUM_BOOL=false;
      },

      fileUpload() {
        if(this.CMP_ID===''){
          this.common_alert(this.alertMsg.newCmpIdEmpty, "error");
          return;
        }
        this.CAP0210Param={
          headerTitle: "참여 허용된 전화번호 엑셀 업로드",
          data:[],
          type:"FILE",
          cmpId:this.CMP_ID,
          qutnId:this.QUTN_ID,
          rowNum:this.gridDataText2.length,
        };

        this.showDialog('CAP0210');
      },

      submitDialog2(type) {
        this.gridSelected2=[]
        if(this.CAP0210Param.data.length>0) {
          this.tab3ItemSett(this.CMP_ID);
        }
        
        this.hideDialog(type);
        this.common_alert(this.alertMsg.success, 'chk');
      },

      deleteRow() {
        if(this.gridSelected2.length<=0){
          this.common_alert('선택된 데이터가 없습니다.', "error");
          return;
        }

        this.common_confirm('삭제하시겠습니까?', this.deleteYesSubTab3, null, null, null, 'chk');
      },

      deleteYesSubTab3() {
        const selectRows=this.gridSelected2;
        let noSelectDatas=[];
        
        for (let index = 0; index < this.gridDataText2.length; index++) {
          const element = this.gridDataText2[index];
          
          let selRowChk=true
          for (let i = 0; i < selectRows.length; i++) {
            const selData = selectRows[i];
            if(element.PHONE_NUMBER==selData.PHONE_NUMBER) {
              selRowChk=false;
            }
          }

          if(selRowChk){
            noSelectDatas.push(element);
          }

        }
        this.gridDataText2=noSelectDatas;

        let phAllBool=true;
        for (let index = 0; index < this.gridDataText2.length; index++) {
          const selData = this.gridDataText2[index];
          
          if(this.mixin_isEmpty(selData.CMP_ALLOW_PARTCP_PHONE_NUM_ID)) {
            phAllBool=false;
          }
        }
        //캠페인별 참여허용된 전화번호 DB 데이터 비교여부
        this.PH_ALLOW_NUM_BOOL=phAllBool;

        //삭제 api
        this.fnAllowPhDelAction();
        
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle:false,
        });
      },

      async fnAllowPhDelAction() {

        let dbDelDatas=[];
        for (let index = 0; index < this.gridSelected2.length; index++) {
          const selData = this.gridSelected2[index];
          
          if(!this.mixin_isEmpty(selData.CMP_ALLOW_PARTCP_PHONE_NUM_ID)) {
            dbDelDatas.push(selData);
          }
        }

        if(dbDelDatas.length>0) {
          let requestData = {
            headers: {
              METHOD: 'delete',
            },
            sendData:{}
          };
    
          requestData.headers["URL"] = "/api/hlw/campaign/manage/allowPartcp/phoneNum/delete";
    
          requestData.sendData["CMP_ID"]=this.CMP_ID;
          requestData.sendData["ROW_DATAS"]=dbDelDatas;
          requestData.sendData["USER_ID"]=this.USER_ID;
          
          let responseData = await this.common_postCall(requestData);
          let resHeader = responseData.HEADER;
          let resData = responseData.DATA;
    
          if (resHeader.ERROR_FLAG) {
            this.common_alert(this.alertMsg.error, "error");
            return false;
          }else{
            if(resData.length>0) {
              this.gridDataText2=resData;
            }
            this.finishdAlert();
          }
        }

      },

      saveRow () {
        if(this.CMP_ID==='') {
          this.common_alert(this.alertMsg.newCmpIdEmpty, "error");
          return;
        }

        this.common_confirm('저장하시겠습니까?', this.saveYesSubTab3, null, null, null, 'chk');
      },

      async saveYesSubTab3() {

        const rows=this.gridDataText2;
        let dbSaveDatas=[];
        
        for (let index = 0; index < rows.length; index++) {
          const element = rows[index];
          if(this.mixin_isEmpty(element.CMP_ALLOW_PARTCP_PHONE_NUM_ID)) {
            dbSaveDatas.push(element);
          }
        }

        if(dbSaveDatas.length>0) {
          let requestData = {
            headers: {
              METHOD: 'regist',
            },
            sendData:{}
          };
    
          requestData.headers["URL"] = "/api/hlw/campaign/manage/allowPartcp/phoneNum/regist";
    
          requestData.sendData["CMP_ID"]=this.CMP_ID;
          requestData.sendData["QUTN_ID"]=this.QUTN_ID;
          requestData.sendData["ROW_DATAS"]=dbSaveDatas;
          requestData.sendData["REG_ID"]=this.USER_ID;
          requestData.sendData["UPD_ID"]=this.USER_ID;
          
          let responseData = await this.common_postCall(requestData);
          let resHeader = responseData.HEADER;
          let resData = responseData.DATA;
    
          if (resHeader.ERROR_FLAG) {
            this.common_alert(this.alertMsg.error, "error");
            return false;
          }else{
            if(resData.length>0) {
              this.gridDataText2=resData;
            }
            this.finishdAlert();

            this.PH_ALLOW_NUM_BOOL=true;
          }
    
        }

      },

      fnRevTimeCollApp() {
        this.CHO_DATES=[];

        for (let i = 0; i < this.resDayArr.length; i++) {
          const btnId=document.getElementById('datesBtn'+i);
          btnId.style.background='';
          btnId.style.border='';
        }
      },

    },

    computed: {
      initHeaders(){
        return {
          SERVICE : 'hlw.campaign.manage',
          METHOD : "",
          TYPE : 'BIZ_SERVICE',
        };
      },

      computedDateFormatted2() {
        return `${this.CMP_OPEN_DT} ${this.CMP_OPEN_TIME}`;
      },

      computedDateFormatted3() {
        return `${this.CMP_END_DT} ${this.CMP_END_TIME}`;
      },

      retformOneParams(){
        return {
          CMP_ID:this.CMP_ID,
          CONSTRUCTION_COMPANY_NAME:this.CONSTRUCTION_COMPANY_NAME,
          BUSINESS_PLACE:this.BUSINESS_PLACE,
          CAMPAIGN_TYPE:this.CAMPAIGN_TYPE,
          DATES:this.DATES[0]+ ' ~ ' +this.DATES[1],
          CAMP_NAME:this.CAMP_NAME,
          USE_YN:this.USE_YN,
          MAIN_IMAGE:this.MAIN_IMAGE,
          SERVICE_LOGO:this.SERVICE_LOGO,
          CONSULTATION_APPLICATION_IMAGE:this.CONSULTATION_APPLICATION_IMAGE,
          BUTTON_TEXT:this.BUTTON_TEXT,
          DUPLICATE_PARTICIPATION_STATUS:this.DUPLICATE_PARTICIPATION_STATUS,
          FINISHED_LINK:this.FINISHED_LINK,
          MAX_RESERVATION:this.MAX_RESERVATION,
          CHATBOT_TYPE:this.CHATBOT_TYPE,
          UPLD_GROUP_ID:this.UPLD_GROUP_ID,
        };
      },

    },
  };
</script>

<style>
    .textArea {
        text-align: center;
    }

    .color {
      background: #d4d232!important;
      border-color: #3c50aa!important;
    }

    .cmp-subcont {
      /* height: 790px!important; */
      /* height: 620px!important; */
    }

    .v-tabs-slider {
      height: 0!important;
    }

    .divWidth {
      height: 150px !important;
    }

    .divWidth > .v-text-field > .v-input__control > .v-input__slot:before {
      border-style: none;
    }

    .menuable__content__active {
      max-height: 77vh !important;
    }

    .divWidth > .v-text-field > .v-input__control > .v-input__slot:before, .v-text-field > .v-input__control > .v-input__slot:after {
      content: none;
      transition:none;
    }

    .timeRngTd {
      padding-top: 20px; 
      padding-bottom: 37px;
    }

</style>
